<template>
  <div>
    <!-- header -->
    <b-container fluid class="sticky-top" style="z-index: 4!important;">
      <b-row align-h="between" class="top-shelf py-2">
        <b-col lg="2" sm="2">
          <b-link class="ml-3" @click="$router.push('/app/prod/inventario')">
            <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
            <span class="pl-2">Atrás</span>
          </b-link>
        </b-col>
        <b-col v-if="ready" lg="8" sm="8" class="pt-3">
          <h4>
            <div v-if="!item.is_bundle" class="text-secondary">
              <font-awesome-icon :icon="['far', 'warehouse-alt']" size="lg" fixed-width />
              <span class="pl-1">Artículo: {{ item.item_code }}</span>
            </div>
            <div v-else class="text-warning">
              <font-awesome-icon :icon="['far', 'boxes-alt']" size="lg" />
              <span class="pl-1">Bulto: {{ item.item_code }}</span>
            </div>
          </h4>
        </b-col>
        <b-col v-if="ready" lg="2" sm="2" class="text-right">
          <b-img-lazy v-if="itemPhotos.length > 0 && this.form.coverPhoto" rounded="circle" fluid :src="coverPhotoUrl" class="m-1 header-foto" alt="photo"></b-img-lazy>
        </b-col>
      </b-row>
    </b-container>
    <!-- Content -->
    <ContentView v-if="ready">
      <b-row class="mb-2" align-h="between">
        <b-col cols="6">
          <h4 class="text-primary">General</h4>
        </b-col>
        <b-col cols="6" class="text-right">
          <WarehouseDataWidget v-if="item.is_valid" :is-distribution="isDistributor" :is-installation="isInstaller" :item="item" @item-stock-updated="updateItemStock" />
          <PurchaseOrderDataWidget v-if="item.is_valid && !isDistributor && !isInstaller" :item="item" />
        </b-col>
      </b-row>
      <!-- General info -->
      <b-row id="general_item_info" class="pl-2">
        <b-col cols="12">
          <b-table-simple small class="border-bottom">
            <b-tbody>
              <b-tr v-if="isAdmin">
                <b-th style="width: 350px;">Status:</b-th>
                <b-td v-if="item.is_valid">
                  <span class="text-success pr-1">Activo</span>
                  <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                </b-td>
                <b-td v-else>
                  <span class="text-danger pr-1">Inactivo</span>
                  <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th style="width: 350px;">Código artículo:</b-th>
                <b-td>{{item.item_code}}</b-td>
              </b-tr>
              <b-tr v-if="!isDistributor && !isInstaller">
                <b-th style="width: 350px;">Stock de bodega principal (01):</b-th>
                <b-td>{{item.stock}}</b-td>
              </b-tr>
              <b-tr>
                <b-th style="width: 350px;">Grupo:</b-th>
                <b-td>
                  <span v-if="item.item_group">{{item.item_group.name}}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th style="width: 350px;">Código cabys:</b-th>
                <b-td v-if="item.sap_cabys_code">{{item.sap_cabys_code}}</b-td>
                <b-td v-else>
                  <div class="text-secondary font-weight-bold">
                    <span class="mb-2">Pendiente</span>
                    <font-awesome-icon :icon="['far', 'info-circle']" size="sm"/>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th style="width: 350px;">Descripción corta:</b-th>
                <b-td>{{item.description}}</b-td>
              </b-tr>
              <b-tr>
                <b-th style="width: 350px;">Descripción completa:</b-th>
                <b-td>{{item.description_lg}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- bundle items -->
      <b-row v-if="ready && item.is_bundle" class="pl-2">
        <b-col cols="3" class="pl-4">
          <b>Artículos bulto:</b>
        </b-col>
        <b-col class="ml-4">
          <div v-if="item.bundle_items.length > 0">
            <ul v-for="bundle in item.bundle_items" :key="bundle.id" class="mb-0">
              <li>{{ bundle.quantity }} {{ bundle.subproduct.item_code }}</li>
            </ul>
          </div>
          <div v-else>
            <span class="text-danger">Esté bulto no tiene artículos</span>
          </div>
        </b-col>
      </b-row>
      <hr>
      <!-- Images -->
      <b-row v-if="ready && !item.is_bundle" class="mb-2">
        <b-col>
          <h4 class="text-primary">Imágenes <sup>({{ itemPhotos.length }})</sup></h4>
        </b-col>
      </b-row>
      <b-row v-if="ready && !item.is_bundle" align-h="center" id="item_photos" class="pl-2">
        <b-col cols="12" lg="2" class="text-center pb-2">
          <ImageUploader @photo-added="createPhoto" @preview-generated="storePreview" />
        </b-col>
        <b-col cols="12" lg="10">
          <PhotoGallery
            v-if="ready && photoList.length > 0"
            :photos="itemPhotos"
            :cover-photo-id="form.coverPhoto"
            @delete-photo="deletePhoto"
            @update-cover="updateItem"
          />
        </b-col>
      </b-row>
    </ContentView>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import ImageUploader from '../shared/ImageUploader'
import ContentView from '../shared/ContentView'
import WarehouseDataWidget from '../shared/WarehouseDataWidget'
import PurchaseOrderDataWidget from '../shared/PurchaseOrderDataWidget'
import PhotoGallery from '../shared/PhotoGallery'
import ToastAlert from '../shared/ToastAlert'

export default {
  name: 'ItemShow',
  components: {
    ImageUploader,
    ContentView,
    WarehouseDataWidget,
    PurchaseOrderDataWidget,
    ToastAlert,
    PhotoGallery
  },
  data () {
    return {
      id: Number(this.$route.params.id),
      item : [],
      form: {
        coverPhoto: null
      },
      photoData: {},
      photoList: [],
      photoSlide: 0,
      photoSliding: null,
      processing: false,
      ready: false,
      bTabIndex: 0,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  mounted () {
    this.fetchItemData()
  },
  computed : {
    ...mapGetters (['isAdmin' ,'isDistributor', 'isInstaller']),
    itemPhotos () {
      return this.photoList.map(id => this.photoData[id])
    },
    coverPhotoUrl() {
      if (this.photoList.find(id => id === this.form.coverPhoto)) {
        return this.photoData[this.form.coverPhoto].img_url
      }
      return ''
    }
  },
  methods : {
    fetchItemData () {
      if (this.processing) { return }
      this.ready = false
      this.processing = true
      this.$http.get(`/api/production/products/${this.id}`)
        .then((resp) => {
          console.log(resp.data)
          this.item = resp.data
          this.form.coverPhoto = resp.data.cover_photo_id
          const data = normalize(
            { photos: resp.data.photos },
            { photos: [ new schema.Entity('photos') ] }
          )
          if (data.entities.hasOwnProperty('photos')) {
            this.photoData = data.entities.photos
          }
          this.photoList = data.result.photos
          delete this.item.photos
          this.ready = true
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
        })
    },
    updateItem (id) {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/products/${this.id}`, {
        product: { cover_photo_id: id }
      })
        .then((resp) => {
          console.log(resp)
          this.item = resp.data
          this.form.coverPhoto = resp.data.cover_photo_id
          const data = normalize(
            { photos: resp.data.photos },
            { photos: [ new schema.Entity('photos') ] }
          )
          if (data.entities.hasOwnProperty('photos')) {
            this.photoData = data.entities.photos
          }
          this.photoList = data.result.photos
          delete this.item.photos
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
        })
    },
    updateItemStock (stock) {
      this.item.stock = stock
      // this.$set(this.itemData, this.selectedItem.id, this.selectedItem)
    },
    // Photos
    createPhoto (form) {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/products/${this.id}/photos`, {
        photo: form
      })
        .then((res) => {
          console.log(res);
          this.item.photos_count++
          const createdPhoto = res.data.photo
          if (this.photoList.length == 0) {
            this.form.coverPhoto = createdPhoto.id
          }
          createdPhoto.img_url = this.storedPreview
          createdPhoto.img_url_lg = this.storedPreview
          this.photoList.unshift(createdPhoto.id)
          this.$set(this.photoData, createdPhoto.id, createdPhoto)
          this.alertVariant = 'success'
          this.alertMessage = 'Imagen creada exitosamente'
          this.alertShow = true
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No fue posible crear la imagen'
          this.alertShow = true
        })
        .finally(this.processing = false)
    },
    deletePhoto (id) {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/products/${this.id}/photos/${id}`)
        .then((res) => {
          console.log(res)
          this.item.photos_count--
          if (this.item.cover_photo_id == id) {
            this.form.coverPhoto = null
            this.item.cover_photo_id = null
          }
          delete this.photoData[res.data.photo.id]
          this.photoList = this.photoList.filter(id => id !== res.data.photo.id)
          this.alertVariant = 'success'
          this.alertMessage = 'Imagen eliminada'
          this.alertShow = true
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No fue posible eliminar la imagen'
          this.alertShow = true
        })
        .finally(this.processing = false)
    },
    storePreview (preview) {
      this.storedPreview = preview
    },
    prevPhoto() {
      this.$refs.photoCarousel.prev()
    },
    nextPhoto() {
      this.$refs.photoCarousel.next()
    },
    onSlideStart (slide) {
      this.photoSliding = true
    },
    onSlideEnd (slide) {
      this.photoSliding = false
    }
  }
}
</script>

<style scoped>
.header-foto {
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
}
</style>