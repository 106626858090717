export const businessUnits = [
  { value: 'ALM', text: 'Almacenes' },
  { value: 'CA', text: 'Control Acceso' },
  { value: 'CAL', text: 'Calidad' },
  { value: 'CM', text: 'Contratos Mantenimiento' },
  { value: 'COM', text: 'Compras' },
  { value: 'CON', text: 'Contabilidad' },
  { value: 'CSC', text: 'Centro Servicio ' },
  { value: 'DI', text: 'Division Industrial' },
  { value: 'EX', text: 'Exportación' },
  { value: 'FB', text: 'Fabricaciones' },
  { value: 'FCT', text: 'Facturación' },
  { value: 'FIN', text: 'Finanzas' },
  { value: 'FLO', text: 'Flotilla' },
  { value: 'GCA', text: 'Gerencia Calidad' },
  // { value: 'GDT', text: 'Gerencia de Distribución' },
  { value: 'GFI', text: 'Gerencia Financiera' },
  { value: 'GJD', text: 'Gerencia General y Junta Directiva' },
  { value: 'GMV', text: 'Gerencia Mercadeo y Ventas' },
  { value: 'GOP', text: 'Gerencia Operaciones' },
  { value: 'GOP1', text: 'Gerencia Producción' },
  { value: 'HZ', text: 'Horizontales' },
  // { value: 'ID', text: 'Investigación y Desarrollo' },
  // { value: 'ING', text: 'Ingeniería' },
  { value: 'TREA', text: 'TREA' },
  { value: 'MKT', text: 'Mercadeo' },
  { value: 'MR', text: 'Mercados Regionales' },
  { value: 'PA', text: 'Puertas Automáticas' },
  { value: 'PK', text: 'Parking' },
  // { value: 'PRO', text: 'Ventas Proyectos' },
  { value: 'PROD', text: 'Producción' },
  { value: 'PVT', text: 'Post Venta (ST)' },
  { value: 'RRH', text: 'Recursos Humanos' },
  // { value: 'SAC', text: 'Post Venta' },
  // { value: 'SG', text: 'Servicios Generales' },
  { value: 'ST', text: 'Servicio Técnico' },
  { value: 'TA', text: 'Tienda Alajuela' },
  { value: 'TC', text: 'Tienda Central Calle Blancos' },
  { value: 'TG', text: 'Tienda Guanacaste' },
  { value: 'TH', text: 'Tienda Heredia' },
  { value: 'TIC', text: 'Tecnología de Información' },
  { value: 'TT', text: 'Tienda Tibás' },
  { value: 'TZ', text: 'Tienda Zapote' },
  { value: 'TKM', text: 'Asesores de venta de PVT y CSC' },
  { value: 'MAK', text: 'Mercadeo Automática' },
  { value: 'COOR', text: 'Coordinadores' },
  { value: 'CS-ATK', text: 'Centro Servicio Automática' },
  { value: 'TD', text: 'Tienda Digital' },
  { value: 'TCA', text: 'Tienda Cartago' },
  { value: 'VTS', text: 'Asesores Ventas' },
  { value: 'GEN-ATK', text: 'General automatika' }
]
