<template>
  <div v-if="isAuthorizedBundleUser">
    <b-row align-h="between" class="custom-tabs mx-0">
      <b-link class="ml-3" @click="$router.go(-1)">
        <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
        <span class="pl-2">Atrás</span>
      </b-link>
      <ul>
        <!-- Nav Tabs ? -->
      </ul>
    </b-row>
    <ContentView class="pb-5">
      <b-row align-v="center">
        <b-col>
          <p class="text-center fs-20">Gestión de Bultos</p>
        </b-col>
      </b-row>
      <b-row align-h="between" align-v="end" class="my-1">
        <div>
          <b-form-group class="m-0" style="width: 280px">
            <b-input-group size="sm">
              <b-input
                v-model="BundleSearchTerm"
                autofocus
                placeholder="Código o descripción"
                @keyup.enter="searchBundles"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" @click="searchBundles">
                  <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse fixed-width />
                  <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div class="text-info pl-2">
            <small v-if="count">{{ count.toLocaleString() }} bultos activos en total</small>
            <small v-else> 0 bultos activos en total</small>
          </div>
        </div>
        <Button size="sm" variant="green" class="mb-4" @click="addBundleModalShow = true">
          <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
          <span class="pl-2">Agregar Bulto</span>
        </Button>
      </b-row>
      <b-row>
        <b-table
          :fields="fields"
          :items="bundles"
          hover
          outlined
          responsive
          show-empty
          class="fs-13"
          @row-clicked="goToBundle"
        >
          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No hay bultos</span>
            </div>
          </template>

          <!-- Table Data -->
          <template v-slot:cell(item_code)="data">
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>

          <template v-slot:cell(subproducts)="data">
            <div v-if="data.value.length > 0">
              <p v-for="item in data.value" :key="item.id" class="mb-1">{{ item.item_code }}</p>
            </div>
            <div v-else class="text-danger">
              <font-awesome-icon :icon="['far', 'exclamation-circle']" />
              <span>Faltan artículos</span>
            </div>
          </template>

          <template v-slot:cell(price)="data">
            <span v-if="data.item.price_currency == 'USD'">
              <span>{{ data.value | dollarize }}</span>
            </span>
            <span v-else-if="data.item.price_currency == 'COL'">
              <span>{{ data.value | colones2 }}</span>
            </span>
            <span v-else>{{ data.item.price_currency }} {{ data.value }}</span>
          </template>

          <template v-slot:cell(installer_price)="data">
            <span v-if="data.item.installer_price_currency == 'USD'">
              <span>{{ data.value | dollarize }}</span>
            </span>
            <span v-else-if="data.item.installer_price_currency == 'COL'">
              <span>{{ data.value | colones2 }}</span>
            </span>
            <span v-else>{{ data.item.installer_price_currency }} {{ data.value }}</span>
          </template>
        </b-table>
      </b-row>
      <Observer v-if="!loading" @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>

    <!-- Add Bundle Modal -->
    <b-modal v-model="addBundleModalShow" title="Agregar bulto" size="lg" centered @hide="reset">
      <b-row align-h="center" class="mt-4">
        <b-form-group class="mb-5" style="width: 270px">
          <b-input-group size="sm">
            <b-input
              v-model="searchTerm"
              autofocus
              placeholder="Buscar artículo por código"
              @keyup.enter="search"
            />
            <b-input-group-append>
              <b-button variant="outline-secondary" size="sm" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="fs-14">
        <b-col lg="4" cols="4" class="text-right">
          <span class="text-info">Artículo a agregar:</span>
        </b-col>
        <b-col lg="6" cols="8">
          <b-container v-if="bundleToAdd">
            <b-row>
              <p class="font-weight-bold">{{ bundleToAdd.item_code }}</p>
            </b-row>
            <b-row>
              <p>{{ bundleToAdd.description }}</p>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 mb-2">
        <Button variant="green" :disabled="!bundleToAdd" @click="addBundle">Agregar</Button>
      </b-row>
      <template #modal-footer><span></span></template>
    </b-modal>

    <!-- Search Results Modal -->
    <b-modal v-model="searchResultsModalShow" scrollable centered hide-header hide-footer>
      <b-row class="px-3">
        <small class="text-info">Seleccionar un artículo:</small>
      </b-row>
      <b-row align-h="center">
        <b-list-group v-if="searchResults.length > 0" class="fs-13">
          <b-list-group-item
            v-for="item in searchResults"
            :key="item.id"
            class="clickable"
            @click="selectItem(item)"
          >
            <b-row>
              <b-col cols="4">{{ item.item_code }}</b-col>
              <b-col cols="8">{{ item.description }}</b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <small v-else class="text-danger">No resultados</small>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import Observer from '../shared/Observer'
import SpinnerHorizontal from '../shared/SpinnerHorizontal'

export default {
  name: 'BundleIndex',
  components: { Button, ContentView, Observer, SpinnerHorizontal },
  data () {
    return {
      bundleData: {},
      bundleList: [],
      searchResults: [],
      addBundleModalShow: false,
      bundleToAdd: null,
      searchResultsModalShow: false,
      searchTerm: '',
      BundleSearchTerm: '',
      fields: [
        { key: 'item_code', label: 'Código', tdClass: 'clickable', thStyle: { minWidth: '60px' }},
        { key: 'description', label: 'Descripción', tdClass: 'clickable' },
        { key: 'subproducts', label: 'Artículos', tdClass: 'clickable' },
        { key: 'price', label: 'Precio Público', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'installer_price', label: 'Precio Instal.', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'stock', thClass: 'text-center', tdClass: 'text-center clickable' }
      ],
      page: 1,
      count: null,
      last: null,
      loading: false,
      processing: false,
      addingPage: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isSalesSupervisor', 'isProductLeader']),
    bundles() { return this.bundleList.map(id => this.bundleData[id]) },
    isAuthorizedBundleUser() {
      if (this.isAdmin || this.isSalesSupervisor || this.isProductLeader) { return true}
      return false
    }
  },
  mounted () {
    this.loading = true
    this.fetchBundles()
  },
  methods: {
    fetchBundles () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/production/bundles', {
          params: {
            q: this.BundleSearchTerm,
            page: this.page
          }
        })
        .then((res) => {
          console.log(res)
          const bundleData = normalize(
            { bundles: res.data.bundles },
            { bundles: [ new schema.Entity('bundles') ] }
          )

          if (this.addingPage) {
            // add to existing data
            if (bundleData.entities.hasOwnProperty('bundles')) {
              this.bundleData = Object.assign({}, this.bundleData, bundleData.entities.bundles)
            }
            this.bundleList = [...this.bundleList, ...bundleData.result.bundles]
          } else {
            // seed fresh data
            if (bundleData.entities.hasOwnProperty('bundles')) {
              this.bundleData = bundleData.entities.bundles
            }
            this.bundleList = bundleData.result.bundles
          }
          this.count = res.data.pagy.count;
          this.last = res.data.pagy.last;
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
          this.processing = false
          this.addingPage = false
        })
    },
    searchBundles () {
      this.page = 1
      this.fetchBundles()
    },
    addBundle () {
      if (this.processing) { return }
      this.processing = true
      console.log(this.bundleToAdd.id)
      this.$http.patch(`/api/production/products/${this.bundleToAdd.id}`, {
          product: { is_bundle: true }
        })
        .then(res => this.goToBundle(res.data))
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    search () {
      if (this.searchTerm) {
        if (this.processing) { return }
        this.processing = true
        this.$http.get('/api/production/products/search', {
            params: {
              q: this.searchTerm,
              filter: 'non-bundle'
            }
          })
          .then((res) => {
            console.log(res)
            this.searchResults = res.data.search_results
            this.searchResultsModalShow = true
          })
          .catch(e => console.log(e))
          .finally(this.processing = false)
      }
    },
    selectItem (item) {
      this.bundleToAdd = item
      this.searchResultsModalShow = false
    },
    intersected () {
      if (this.page + 1 <= this.last) {
        this.page++
        this.addingPage = true
        this.fetchBundles()
      }
    },
    goToBundle (item) {
      this.$router.push(`/app/prod/inventario/bultos/${item.id}`)
    },
    reset () {
      this.searchResults = []
      this.bundleToAdd = null
    }
  }
}
</script>
