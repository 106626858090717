export const businessUnits = [
  { value: "DI", text: "División Industrial" },
  // { value: "SG", text: "Servicios Generales" },
  { value: "FB",text: "Fabricaciones" },
  { value: "EX",text: "Exportación" },
  { value: "FCT",text: "Facturación" },
  { value: "CAL",text: "Calidad" },
  { value: "COP",text: "Corporativo" },
  { value: "FIN",text: "Finanzas" },
  { value: "COM",text: "Compras" },
  { value: "FLO",text: "Flotilla" },
  { value: "GEN1",text: "General" },
  { value: "GFI",text: "Gerencia Financiera" },
  { value: "GJD",text: "Gerencia General y Junta Directiva" },
  { value: "GMV",text: "Gerencia Mercadero y Ventas" },
  { value: "HZ", text: "Horizontales" },
  // { value: "ING",text: "Ingeniería" },
  { value: "PA",text: "Puertas Automáticas" },
  { value: "GCA",text: "Gerencia Calidad" },
  // { value: "GDT",text: "Gerencia de Distribución" },
  { value: "PK",text: "Parking" },
  // { value: "PRO",text: "Ventas Proyectos" },
  { value: "PVT",text: "Post venta (ST)" },
  { value: "RRH",text: "Recursos Humanos" },
  // { value: "SAC",text: "Post Venta" },
  { value: "ST",text: "Servicio Técnico" },
  { value: "TA",text: "Tienda Alajuela" },
  { value: "TC",text: "Tienda Central Calle Blancos" },
  { value: 'TKM', text: 'Asesores de venta de PVT y CSC' },
  { value: "ALM",text: "Almacenes" },
  { value: "MR",text: "Mercados Regionales" },
  { value: "MKT",text: "Marketing" },
  { value: "TG",text: "Tienda Guanacaste" },
  { value: "TH",text: "Tienda Heredia" },
  { value: "TIC",text: "Tecnología de Información"},
  // { value: "TT", text: "Tienda Tibás" },
  // { value: "BA", text: "Barreras" },
  { value: "TZ", text: "Tienda Zapote" },
  { value: "VV", text: "Video Vigilancia" },
  { value: "GOP", text: "Gerencia Operaciones" },
  { value: "GOP1", text: "Gerencia Producción" },
  { value: "SSO", text: "Seguridad y Salud Ocupacional" },
  { value: "CA", text: "Control Acceso"},
  { value: "CM", text: "Contratos Mantenimiento" },
  { value: "CON", text: "Contabilidad" },
  { value: "CSC", text: "Centro Servicio COAA" },
  { value: "DIB", text: "Dibujantes" },
  { value: 'MAK', text: 'Mercadeo Automática' },
  { value: 'COOR', text: 'Coordinadores' },
  { value: 'CS-ATK', text: 'Centro Servicio Automática' },
  { value: 'TD', text: 'Tienda Digital' },
  { value: 'TCA', text: 'Tienda Cartago' },
  { value: 'TREA', text: 'TREA' },
  { value: 'VTS', text: 'Asesores Ventas' },
  { value: 'GEN-ATK', text: 'General automatika' }
  // { value: "Centr_z", text: "Centro de coste general"}
]
