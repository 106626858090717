<template>
  <div>
    <!-- Client specific TopShelf -->
    <b-container fluid  class="sticky-top" style="z-index: 4!important;">
      <b-row align-h="between" class="top-shelf py-4">
        <b-col lg="2" sm="12" class="pb-sm-3">
          <b-link class="ml-3" @click="$router.push('/app/prod/clientes')">
            <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
            <span class="pl-2">Atrás</span>
          </b-link>
        </b-col>
        <b-col lg="6" sm="9">
          <TitleBadge type="Client" :id="isLoading ? 'cargando...' : ready ? client.client_num : 'error'" :title="isLoading ? 'cargando...' : ready ? client.name : 'error'" />
        </b-col>
        <b-col lg="4" sm="3" class="text-right">
          <b-row v-if="ready && client.sap_valid" class="mr-2">
            <b-col>
              <Button
                v-if="isDev || isClientAdmin"
                variant="red"
                class="ml-2 mt-2"
                :disabled="!ready"
                @click="confirmModal = true"
              >
                <b-spinner v-if="isInactivatingClient" small type="grow" class="mr-2" />
                <font-awesome-icon v-else :icon="['far', 'exclamation']" fixed-width />
                <span class="pl-1">{{ isInactivatingClient ? 'Inactivando' : 'Inactivar' }}</span>
              </Button>
              <Button
                v-if="client.client_num && client.client_num.charAt(0) == 'C'"
                variant="yellow"
                class="ml-2 mt-2"
                :disabled="!client.sap_valid || !ready"
                @click="$router.push({ name: 'ClientUpdate', params: { id: id}})"
              >
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-1">Editar</span>
              </Button>
            </b-col>
          </b-row>
          <b-row v-else class="mr-2">
            <b-col>
              <div v-if="ready && client.sap_frozen">
                <p class="name text-danger">INACTIVO</p>
                <p v-if="client.sap_frozen_remarks">[ {{ client.sap_frozen_remarks }} ]</p>
              </div>
              <div v-else>
                <p class="name text-danger">INVÁLIDO</p>
                <p v-if="ready">Este cliente esta inválido en SAP</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <ContentView>
      <b-row>
        <b-col md="12" class="px-0">
          <b-tabs class="fs-18" active-nav-item-class="font-weight-bold text-primary" align="center" v-model="bTabIndex">
            <b-tab :title-link-class="bTabClass(0)" title="Info General">
              <b-row v-if="isLoading || !ready" class="dash-card" style="min-height: 265px">
                <b-container fluid class="fs-13">
                  <b-row align-h="center" class="h-100 justify-content-center align-items-center">
                    <span v-if="isLoading" class="text-info">
                      <b-spinner small type="grow" class="mr-2" />
                      Cargando datos del cliente...
                    </span>
                    <span v-else class="text-danger">Error al cargar datos del cliente, intente de nuevo</span>
                  </b-row>
                </b-container>
              </b-row>
              <b-row v-else class="dash-card" style="min-height: 265px">
                <b-col lg="6" sm="12">
                  <table v-if="ready" class="table table-borderless table-show table-sm"  style="font-size: 1rem">
                    <tr>
                      <td style="width: 230px">Tipo de ID:</td>
                      <td v-if="client.sap_gln">{{ cedulaType }}</td>
                      <td v-else class="text-danger"> Sin información</td>
                    </tr>
                    <tr>
                      <td>Número cédula:</td>
                      <td v-if="client.cedula">{{ client.cedula }}</td>
                      <td v-else class="text-danger"> Sin información</td>
                    </tr>
                    <tr>
                      <td>Categoría cliente:</td>
                      <td v-if=" client.rank">{{ client.rank }}</td>
                      <td v-else class="text-danger"> Sin información</td>
                    </tr>
                    <tr>
                      <td>Tipo socio negocios:</td>
                      <td>
                        <span v-if="client.client_type == 'cCompany'">Sociedades</span>
                        <span v-else-if="client.client_type == 'cPrivate'">Privado</span>
                        <span v-else class="text-danger"> Sin información</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Nombre sector:</td>
                      <td v-if="client.sector">{{ client.sector }}</td>
                      <td v-else class="text-danger"> Sin información</td>
                    </tr>
                    <tr>
                      <td>Saldo de cuenta:</td>
                      <td>{{ client.current_account_balance }}</td>
                    </tr>
                    <tr>
                      <td>Condiciones de pago:</td>
                      <td v-if="client.payment_terms">{{ client.payment_terms }}</td>
                      <td v-else class="text-danger"> Sin información</td>
                    </tr>
                  </table>
                </b-col>
                <b-col lg="6" sm="12">
                  <table v-if="ready" class="table table-borderless table-show table-sm"  style="font-size: 1rem">
                    <tr>
                      <td style="width: 230px">Asesor asignado:</td>
                      <td>
                        <span v-if="client.salesperson" :class="{'text-danger': !client.salesperson.status}">
                          {{ client.salesperson | concatName2 }}
                        </span>
                        <span v-else class="text-danger">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Asesor TKM:</td>
                      <td>
                        <span v-if="client.telemarketer" :class="{'text-danger': !client.telemarketer.status}">
                          {{ client.telemarketer | concatName2 }}
                        </span>
                        <span v-else class="text-danger">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Gestor de cobro:</td>
                      <td v-if="client.collection_manager">{{ client.collection_manager }}</td>
                      <td v-else class="text-danger"> - </td>
                    </tr>
                    <tr>
                      <td>Portal web:</td>
                      <td v-if="client.website_url">
                        <a :href="client.website_url " target="_blank">{{client.website_url}}</a>
                      </td>
                      <td v-else class="text-danger"> - </td>
                    </tr>
                    <tr>
                      <td>Notas:</td>
                      <td v-if="client.notes">
                        <span class="text-muted">{{client.notes}}</span>
                      </td>
                      <td v-else class="text-danger"> - </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>

            </b-tab>
            <b-tab :title-link-class="bTabClass(1)" title="Contactos" :disabled="!ready">
              <b-row class="dash-card" style="min-height: 265px">
                <span class="pl-1 text-primary" style="text-decoration: underline;">Datos cliente</span>
                <table v-if="ready" class="table table-borderless table-show table-sm"  style="font-size: 1rem">
                  <tr>
                    <td style="width: 230px">Correo facturación:</td>
                    <td v-if="client.email">
                      <p v-for="email in client.email.split(';')" :key="email" class="mb-0">
                        {{ email.trim() }}
                      </p>
                    </td>
                    <td v-else class="text-danger"> Sin información</td>
                  </tr>
                  <tr>
                    <td>Teléfono1:</td>
                    <td v-if="client.phone">
                      {{ client.phone }}
                      <span v-show="client.phone_extension">ext - {{ client.phone_extension }}</span>
                    </td>
                    <td v-else class="text-danger"> Sin información</td>
                  </tr>
                  <tr>
                    <td>Teléfono2:</td>
                    <td v-if="client.phone_2">{{ client.phone_2 }}</td>
                    <td v-else class="text-danger"> Sin información</td>
                  </tr>
                  <tr>
                    <td>Celular:</td>
                    <td v-if="client.cellular">{{ client.cellular }}</td>
                    <td v-else class="text-danger"> Sin información</td>
                  </tr>
                  <tr>
                    <td>Fax:</td>
                    <td v-if="client.fax">{{ client.fax }}</td>
                    <td v-else class="text-danger"> Sin información</td>
                  </tr>
                  <tr>
                    <td>Persona de contacto:</td>
                    <td v-if="client.contact_person"><b>{{ client.contact_person }}</b></td>
                    <td v-else class="text-danger"> -- </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Button size="sm" class="py-0" @click="openContactsModal">
                        <font-awesome-icon :icon="['far', 'address-card']" fixed-width />
                        <span class="pl-2">Personas de contacto</span>
                      </Button>
                    </td>
                  </tr>
                </table>
              </b-row>
            </b-tab>
            <b-tab :title-link-class="bTabClass(2)"  title="Direcciones" :disabled="!ready">
              <b-row v-if="ready" class="dash-card" style="min-height: 265px">
                <span v-if="addresses.length > 0" class="pl-1 text-primary" style="text-decoration: underline;">Dirección principal</span>
                <table v-if="addresses.length > 0" class="table table-borderless table-show table-sm"  style="font-size: 1rem">
                  <tr>
                    <td style="width: 230px">Nombre dirección:</td>
                    <td v-if="addresses[0].address">{{addresses[0].address}}</td>
                    <td v-else class="text-danger">Falta nombre de dirección</td>
                  </tr>
                  <tr>
                    <td>Dirección:</td>
                    <td v-if="addresses[0].street">{{addresses[0].street}}</td>
                    <td v-else class="text-danger">Falta dirección</td>
                  </tr>
                  <tr>
                    <td>Provincia:</td>
                    <td v-if="addresses[0].province">{{addresses[0].province}}</td>
                    <td v-else class="text-danger">Falta provincia</td>
                  </tr>
                  <tr>
                    <td>Cantón:</td>
                    <td v-if="addresses[0].canton">{{addresses[0].canton}}</td>
                    <td v-else class="text-danger">Falta canton</td>
                  </tr>
                  <tr>
                    <td>Distrito:</td>
                    <td v-if="addresses[0].district">{{addresses[0].district}}</td>
                    <td v-else class="text-danger">Falta distrito</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Button size="sm" class="py-0" @click="addressesModalShow = true">
                        <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width />
                        <span class="pl-2">Direcciones</span>
                      </Button>
                    </td>
                  </tr>
                </table>
                <b-container v-else fluid class="fs-13">
                  <b-row align-h="center" class="h-100 justify-content-center align-items-center">
                    <span class="text-danger">Cliente sin direcciones registradas</span>
                  </b-row>
                </b-container>
              </b-row>
            </b-tab>
            <b-tab :title-link-class="bTabClass(3)" title="Notas facturación" :disabled="!ready">
              <b-row v-if="ready && (client.client_billing_note && Object.keys(client.client_billing_note).length > 0)" class="dash-card" style="min-height: 265px">
                <b-col>
                  <b-container fluid style="overflow: hidden;">
                    <span class="pl-1 text-primary" style="text-decoration: underline;">Facturación automática</span>
                    <table class="table table-borderless table-show table-sm" style="font-size: 1rem">
                      <b-tr>
                          <b-td style="min-width: 200px">Requiere OC:</b-td>
                          <b-td v-if="client.client_billing_note.purchase_order_required"> <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /> </b-td>
                          <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Requiere crédito:</b-td>
                        <b-td v-if="client.client_billing_note.credit_required"> <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Es exento:</b-td>
                        <b-td v-if="client.client_billing_note.exonerated"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Correo de facturación:</b-td>
                        <b-td v-if="client.client_billing_note.has_billing_mail"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Facturación en colones:</b-td>
                        <b-td v-if="client.client_billing_note.local_currency"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Trámite físico:</b-td>
                        <b-td v-if="client.client_billing_note.physical_procedure"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else><font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Portal de documentos:</b-td>
                        <b-td v-if="client.client_billing_note.document_portal"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Anticipos:</b-td>
                        <b-td v-if="client.client_billing_note.advance_payment"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td >Envío de anexos:</b-td>
                        <b-td v-if="client.client_billing_note.mailing_attachments"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
                        <b-td v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Comentarios adicionales:</b-td>
                        <b-td v-if="client.client_billing_note.notes">{{client.client_billing_note.notes}}</b-td>
                        <b-td v-else class="text-muted"> Sin comentarios </b-td>
                      </b-tr>
                    </table>
                  </b-container>
                </b-col>
                <!-- <b-col lg="5" v-if="client.client_billing_note.exonerated" class="border-left">
                  <span class="pl-1 text-primary" style="text-decoration: underline;">Datos exoneración</span>
                  <table class="table table-borderless table-show table-sm" style="font-size: 1rem">
                    <b-tr>
                      <b-td style="width: 200px">Tipo de documento:</b-td>
                      <b-td v-if="client.exo_doc_type">{{ client.exo_doc_type }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Número de documento:</b-td>
                      <b-td v-if="client.exo_doc_num"> {{ client.exo_doc_num }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Nombre institución:</b-td>
                      <b-td v-if="client.exo_business_name">{{ client.exo_business_name }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Fecha emisión:</b-td>
                      <b-td v-if="client.exo_started_at">{{ client.exo_started_at | formatDate }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Monto impuesto:</b-td>
                      <b-td v-if="client.exo_total_tax">{{ client.exo_total_tax }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Porcentaje compra:</b-td>
                      <b-td v-if="client.exo_purchase_percent">{{ client.exo_purchase_percent }} %</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                  </table>
                </b-col> -->
              </b-row>
              <b-row v-else class="dash-card" style="min-height: 265px">
                <b-container fluid class="fs-13">
                  <b-row align-h="center" class="h-100 justify-content-center align-items-center">
                    <span class="text-danger">Cliente sin notas de facturación</span>
                  </b-row>
                </b-container>
              </b-row>
            </b-tab>
            <b-tab :title-link-class="bTabClass(4)" title="Requerimientos" :disabled="!ready">
              <b-row v-if="ready" class="dash-card" style="min-height: 265px">
                <b-col class="px-0">
                  <b-container v-if="client.client_requirements.length > 0" fluid class="px-0" >
                    <b-form-group style="width: 100%">
                      <b-input-group>
                        <b-input
                          v-model="searchRequirementsTerm"
                          placeholder="Buscar por nombre o categoría"
                        />
                        <b-input-group-append>
                          <b-button variant="outline-secondary" @click="searchRequirementsTerm = ''">
                            <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-container>
                  <b-table-simple
                    v-if="client.client_requirements.length > 0"
                    small
                    responsive
                    style="font-size: medium;"
                  >
                    <b-thead>
                      <b-tr class="font-weight-bold">
                        <b-td style="width: 300px;">Nombre:</b-td>
                        <b-td style="width: 300px;">Categoría:</b-td>
                        <b-td class="text-center" style="width: auto;">Descripción:</b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody v-if="requirements.length > 0">
                      <b-tr v-for="requirement in requirements" :key="requirement.id">
                        <b-td>
                          <span v-if="requirement.name" class="font-weight-bold">{{ requirement.name }}</span>
                          <span v-else> - </span>
                        </b-td>
                        <b-td>
                          <span v-if="requirement.category">{{ requirement.category }}</span>
                          <span v-else> - </span>
                        </b-td>
                        <b-td>
                          <span v-if="requirement.description">{{ requirement.description }}</span>
                          <span v-else> - </span>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                      <b-tr>
                        <b-td colspan="3" class="text-center">
                          <span class="text-danger">Búsqueda sin resultados</span>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-container v-else fluid class="fs-13">
                    <b-row align-h="center" class="h-100 justify-content-center align-items-center">
                      <span class="text-danger">Cliente sin requerimientos registrados</span>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <!-- Projects  -->
      <b-row>
        <b-col>
          <b-row align-h="between">
            <div class="dash-card-title">
              <Button
                :class="showProjects ? null : 'collapsed'"
                :aria-expanded="showProjects ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="blue"
                :disabled="isLoading || !ready"
                @click="showProjects = !showProjects"
              >
                <span>
                  <b-spinner v-if="isLoading" small type="grow" />
                  <span>{{ isLoading ? 'cargando...' : 'Proyectos' }}</span>
                  <font-awesome-icon v-if="!isLoading && !showProjects" :icon="['far', 'eye-slash']" fixed-width />
                  <font-awesome-icon v-else-if="!isLoading && showProjects" :icon="['far', 'eye']" fixed-width />
                </span>
              </Button>
              </div>
            <div v-if="projects.length > 0 && showProjects" class="pr-3">
              <Button variant="orange" @click="openNewFormModal" :disabled="!ready">
                <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
                <span class="pl-1">Nuevo Proyecto</span>
              </Button>
            </div>
          </b-row>
          <b-collapse id="collapse-1" v-model="showProjects">
            <div v-if="ready">
              <div v-if="projects.length > 0">
                <b-card v-for="project in projects" :key="project.id" class="project-card">
                  <b-card-header class="card-header">
                    <b-row align-v="center" class="mb-1 pl-2 border-bottom">
                      <b-col cols="10">
                        <div class="project-card-title">
                          <font-awesome-icon :icon="['far', 'briefcase']" size="sm" fixed-width />
                          <span class="px-2">{{ project | projectId }}:</span>
                          <span>{{ project.name }}</span>
                        </div>
                      </b-col>
                      <b-col v-if="project.user_id === currentUser.id || isAdmin" cols="2" align-self="start" class="text-right pr-1">
                        <Button @click="formMode = 'edit'; openEditFormModal(project.id);">
                          <font-awesome-icon :icon="['far', 'edit']" size="sm" fixed-width />
                        </Button>
                      </b-col>

                    </b-row>
                    <b-row class="lower-row">
                      <b-col sm="4" cols="6">
                        <div v-if="project.project_type === 'Comercial'">
                          <font-awesome-icon :icon="['fas', 'industry-alt']" fixed-width />
                          <span class="pl-2">Comercial</span>
                        </div>
                        <div v-else-if="project.project_type == 'Residencial'">
                          <font-awesome-icon :icon="['fas', 'home']" fixed-width />
                          <span class="pl-2">Residencial</span>
                        </div>
                        <div v-else>
                          <font-awesome-icon :icon="['fas', 'parking']" fixed-width />
                          <span class="pl-2">Parking</span>
                        </div>
                      </b-col>
                      <b-col sm="4" cols="6">
                        <font-awesome-icon :icon="['fas', 'calendar-alt']" fixed-width />
                        <span class="pl-2">{{ project.created_at | formatDate }}</span>
                      </b-col>
                      <b-col sm="4" cols="6">
                        <font-awesome-icon :icon="['fas', 'user-circle']" fixed-width />
                        <span class="pl-2">{{ project.user.first_name + ' ' + project.user.last_name }}</span>
                      </b-col>
                      <b-col sm="4" cols="6">
                        <font-awesome-icon :icon="['fas', 'user-tie']" fixed-width />
                        <span class="pl-2">{{ project.manager }}</span>
                      </b-col>
                      <b-col sm="8" cols="12">
                        <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width />
                        <span class="pl-2">{{ project.address }}</span>
                      </b-col>
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <Button :to="`/app/prod/proyectos/${project.id}`" size="sm">
                        <span>Ver Detalles</span>
                      </Button>
                    </b-row>
                  </b-card-header>
                </b-card>
              </div>
              <div v-else class="dash-card d-flex justify-content-center align-items-center p-0">
                <Button variant="orange" size="sm" @click="openNewFormModal" :disabled="!ready">
                  <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
                  <span class="pl-1">Nuevo Proyecto</span>
                </Button>
              </div>
            </div>
          </b-collapse>
        </b-col>
      </b-row>
    </ContentView>

    <!-- New/Edit Project Modal -->
    <b-modal lazy v-model="formModalShow" centered>
      <template #modal-title>
        <h5 v-if="formMode === 'new'">Crear nuevo proyecto</h5>
        <h5 v-else-if="formMode === 'edit'">Editar {{ selectedProject.name }}</h5>
      </template>
      <b-container>
        <b-row align-h="center">
          <b-form-group label-size="sm" style="width: 300px">
            <template #label>
              <span>Nombre Proyecto</span><span class="asterisk">*</span>
            </template>
            <b-input v-model="form.name" size="sm" />
          </b-form-group>
        </b-row>
        <b-row align-h="center">
          <b-form-group label-size="sm" style="width: 300px">
            <template #label>
              <span>Dirección</span><span class="asterisk">*</span>
            </template>
            <b-textarea v-model="form.address" size="sm"></b-textarea>
          </b-form-group>
        </b-row>
        <b-row align-h="center">
          <b-form-group label="Encargado" label-size="sm" style="width: 300px">
            <b-input v-model="form.manager" size="sm" />
          </b-form-group>
        </b-row>
        <b-row align-h="center">
          <b-form-group label-size="sm" style="width: 300px">
            <template #label>
              <span>Tipo de ciclos de proyecto</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="form.project_type" size="sm" stacked>
              <b-radio value="Comercial">Comercial o Industrial</b-radio>
              <b-radio value="Residencial">Residencial o Condominio</b-radio>
              <b-radio value="Parqueo">Parqueo</b-radio>
            </b-radio-group>
          </b-form-group>
        </b-row>
        <b-row v-if="formMode === 'edit'" align-h="center">
          <b-form-group label="Orden de Pedido" label-size="sm" style="width: 300px">
            <b-input v-model="form.work_order_no" size="sm" />
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button v-if="formMode === 'new'" variant="green" class="float-right" :disabled="!formValid" @click="createProject">Crear</Button>
        <Button v-else variant="green" class="float-right" :disabled="!formValid" @click="updateProject">Guardar</Button>
      </template>
    </b-modal>

    <!-- Contacts Modal -->
    <b-modal v-model="contactsModalShow" title="Personas de contacto" scrollable size="xl" class="p-0" hide-footer>
      <b-row align-h="between">
        <b-col cols="12">
          <b-form-group style="width: 100%">
            <b-input-group>
              <b-input
                v-model="searchContactsTerm"
                placeholder="Buscar por nombre email o teléfono"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" @click="searchContactsTerm = ''">
                  <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-table-simple
          small
          striped
          class="mx-3 fs-16 table-contacts"
        >
          <b-thead>
            <b-tr class="font-weight-bold">
              <b-th sticky-column style="width: 17rem;">Nombre</b-th>
              <b-th style="width: 20rem;">Email</b-th>
              <b-th style="width: 10rem;">Puesto</b-th>
              <b-th style="width: 10rem;">Teléfono 1</b-th>
              <b-th>Teléfono 2</b-th>
              <b-th>Celular</b-th>
              <b-th class="text-center">Activo</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="contacts.length > 0">
            <b-tr v-for="contact in contacts" :key="contact.id" :variant="bgContactVariant(contact.name)">
              <b-td v-if="contact.name" class="font-weight-bold">
                <div>
                  {{ contact.name }}
                  <sup v-if="contact.name == client.contact_person" class="font-weight-bold text-danger pl-1">*</sup>
                </div>
              </b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.email">
                <p v-for="(email, index) in contact.email.split(';')" :key="index">
                  <span>{{email}}</span>
                </p>
              </b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.profession">
                {{ contact.profession }}
              </b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.phone">
                {{ contact.phone }}
                <span v-if="contact.phone_extension"> ext {{ contact.phone_extension }}</span>
              </b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.phone_2">{{ contact.phone_2 }}</b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.cellular">{{ contact.cellular }}</b-td>
              <b-td v-else></b-td>
              <b-td v-if="contact.is_active" class="text-center"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></b-td>
              <b-td v-else class="text-center"> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /> </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <b-tr>
              <b-td colspan="6" class="text-center">
                <span class="text-danger">Búsqueda sin resultados</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
    </b-modal>

    <!-- Addresses Modal -->
    <b-modal v-model="addressesModalShow" title="Direcciones" scrollable class="p-0"  size="xl" hide-footer>
      <b-row align-h="between">
        <b-col cols="12">
          <b-form-group style="width: 100%">
            <b-input-group>
              <b-input
                v-model="searchAddressesTerm"
                placeholder="Buscar por nombre dirección o provincia"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" @click="searchAddressesTerm = ''">
                  <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-table-simple
          small
          responsive
          striped
          class="text-muted mx-3 fs-16"
        >
          <b-thead>
            <b-tr class="font-weight-bold">
              <b-td style="width: 20rem;">Nombre dirección</b-td>
              <b-td style="width: 30rem;">Dirección</b-td>
              <b-td>Provincia</b-td>
              <b-td>Cantón</b-td>
              <b-td>Distrito</b-td>
            </b-tr>
          </b-thead>
          <b-tbody v-if="addresses.length > 0">
            <b-tr v-for="address in addresses" :key="address.id" :variant="bgAddressVariant(address.address)">
              <b-td v-if="address.address" class="font-weight-bold">
                <div>
                  {{ address.address }}
                  <sup v-if="address.address == client.default_address" class="font-weight-bold text-danger pl-1">*</sup>
                </div>
              </b-td>
              <b-td v-else></b-td>
              <b-td v-if="address.street" >{{ address.street }}</b-td>
              <b-td v-else></b-td>
              <b-td v-if="address.province">{{ address.province }}</b-td>
              <b-td v-else></b-td>
              <b-td v-if="address.canton">{{ address.canton }}</b-td>
              <b-td v-else></b-td>
              <b-td v-if="address.district">{{ address.district }}</b-td>
              <b-td v-else></b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <b-tr>
              <b-td colspan="6" class="text-center">
                <span class="text-danger">Búsqueda sin resultados</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
    </b-modal>

    <!-- PaymentTerms Modal -->
    <b-modal  v-if="isDev" v-model="paymentTermsModalShow" centered title="Editar condiciones de pago">
      <b-row align-h="center">
        <b-form-group label="Condiciones de pago" label-size="sm" style="width: 300px">
          <b-select v-model="paymentTermsForm.sap_payment_terms_code" :options="paymentTermsOptions" size="sm">
            <template #first>
              <b-select-option :value="null">- Eligir una opción -</b-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-row>
      <template #modal-footer>
        <Button variant="green" :disabled="!paymentTermsForm.sap_payment_terms_code" @click="updatePaymentTerms">Guardar</Button>
      </template>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- ConfirmModal -->
    <ConfirmModal v-if="confirmModal" :required=true @close="closeConfirmModal" @onConfirm="inactivate">
      <template #header>
        <h5>Inactivar cliente en XYZ</h5>
      </template>
      <template #body>
        <p class="fs-5 font-weight-bold">¿Está seguro que desea inactivar el cliente?</p>
        <p class="text-muted text-center">NOTA: Se inactivará el cliente en XYZ, el proceso es irreversible</p>
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import TitleBadge from '../shared/TitleBadge'
import ToastAlert from '../shared/ToastAlert'
import TopShelf from '../shared/TopShelf'
import ConfirmModal from '../shared/ConfirmModal'
import { provinces } from '../../sharedOptions/provinces'

export default {
  name: 'ClientShow',
  components: { Button, ContentView, TitleBadge, ToastAlert, TopShelf, ConfirmModal },
  data () {
    return {
      id: Number(this.$route.params.id),
      client: null,
      contactData: [],
      paymentTerms: [],
      projectData: {},
      projectList: [],
      addressData:  {},
      addressList: [],
      billingNote: {},
      selectedProject: null,
      formModalShow: false,
      formMode: 'new',
      form: {
        name: null,
        address: null,
        manager: null,
        project_type: null,
        work_order_no: null,
        user_id: null
      },
      contactsModalShow: false,
      addressesModalShow: false,
      paymentTermsModalShow: false,
      paymentTermsForm: {
        sap_payment_terms_code: null
      },
      provinceNames: provinces,
      showProjects: true,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      ready: false,
      isLoading: false,
      isInactivatingClient: false,
      processing: false,
      bTabIndex: 0,
      confirmModal: false,
      searchContactsTerm: '',
      searchAddressesTerm: '',
      searchRequirementsTerm: ''
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isDev', 'isClientAdmin']),
    paymentTermsOptions () {
      return this.paymentTerms.map(term => ({ text: term.name, value: term.sap_group_number }))
    },
    projects () {
      let array = this.projectList.map(id => this.projectData[id])
      return array.sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
    },
    addresses () {
      let newAddresses = this.addressList.map(id => {
        const address = this.addressData[id]

        let provinceName = this.provinceNames.find(el => el.value == address.province)
        if (provinceName) { address.province = provinceName.text }

        address.sap_line_num = (address.address === this.client.default_address) ? 0 : 1
        return address
      })
      const filteredAddresses = newAddresses.filter(val => {
        if (this.searchAddressesTerm) {
          const term = this.searchAddressesTerm.toLowerCase()
          return (
            val.address.toLowerCase().includes(term) ||
            (val.province && val.province.toLowerCase().includes(term)) ||
            (val.street && val.street.toLowerCase().includes(term))
          )
        }
        return true
      })
      return filteredAddresses.sort((a,b) => a.sap_line_num - b.sap_line_num)
    },
    contacts () {
      if (this.contactData) {
        const filteredData = this.contactData.map(val => {
          if (val.name === this.client.contact_person) { val.position = 0 }
          else if (val.is_active) { val.position = 1 }
          else { val.position = 2 }
          return val
        })
        .filter(val => {
          if (this.searchContactsTerm) {
            const term = this.searchContactsTerm.toLowerCase()
            return (
              val.name.toLowerCase().includes(term) ||
              (val.email && val.email.toLowerCase().includes(term)) ||
              (val.phone && val.phone.toLowerCase().includes(term))
            )
          }
          return true
        })
        return filteredData.sort((a, b) => a.position - b.position)
      }
    },
    requirements () {
      const requirements = this.client.client_requirements
      return requirements.filter(requirement => {
        if (this.searchRequirementsTerm) {
          const term = this.searchRequirementsTerm.toLowerCase()
          return (
            requirement.name.toLowerCase().includes(term) ||
            (requirement.category && requirement.category.toLowerCase().includes(term))
          )
        }
        return true
      })
    },
    currentUser () { return this.$store.state.currentUser },
    cedulaType () {
      let type
      if (this.client && this.client.sap_gln) {
        switch (this.client.sap_gln) {
          case '01':
            type = 'Cédula Física'
            break;
          case '02':
            type = 'Cédula Jurídica'
            break;
          case '03':
            type = 'DIMEX'
            break;
          case '04':
            type = 'NITE'
            break;
          case '00':
            type = 'Extranjero'
            break;
          default:
            type = ''
        }
      }
      return type
    },
    corredizoImage () { return CorredizoImage },
    abatibleImage () { return AbatibleImage },
    seccionalImage () { return SeccionalImage },
    cortinaImage () { return CortinaImage },
    formValid () {
      return this.form.name && this.form.address && this.form.project_type ? true : false
    }
  },
  mounted () {
    this.fetchClientData()
  },
  methods: {
    fetchClientData () {
      this.ready = false
      this.isLoading = true
      this.$http.get(`/api/production/clients/${this.id}`)
        .then((res) => {
          // console.log(res.data)
          this.client = res.data.client
          this.client['salesperson'] = res.data.salesperson
          this.client['telemarketer'] = res.data.telemarketer
          // Projects
          const projectData = normalize(
            { projects: res.data.client.projects },
            { projects: [ new schema.Entity('projects') ] }
          )
          if (projectData.entities.hasOwnProperty('projects')) { // in case of empty data
            this.projectData = projectData.entities.projects
          } else {
            this.projectData = {}
          }
          this.projectList = projectData.result.projects

          // Addresses
          const addressData = normalize(
            { addresses: res.data.client.client_addresses },
            { addresses: [new schema.Entity('addresses') ] }
          )
          if (addressData.entities.hasOwnProperty('addresses')) {
            this.addressData = addressData.entities.addresses
          } else {
            this.addressData = {}
          }
          this.addressList = addressData.result.addresses


          // seed detailShow and loading in project data
          for (var key in this.projectData) {
            if (this.projectData.hasOwnProperty(key)) {
              this.$set(this.projectData[key], 'detailShow', false)
              this.$set(this.projectData[key], 'loading', false)
            }
          }
          this.ready = true
          delete this.client['client_contacts']
          delete this.client['client_addresses']
          delete this.client['projects']
        })
        .catch(e => console.log(e))
        .finally(() => this.isLoading = false)
    },
    openContactsModal () {
      this.$http.get(`/api/production/clients/${this.client.id}/client_contacts`)
        .then(response => {
          this.contactData = response.data
          this.contactsModalShow = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    openPaymentTermsModal () {
      this.paymentTermsForm.sap_payment_terms_code = this.client.sap_payment_terms_code
      this.$http.get('/api/production/payment_terms_types')
        .then(response => {
          // console.log(response)
          this.paymentTerms = response.data
          this.paymentTermsModalShow = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    updatePaymentTerms () {
      if (this.processing) return
      this.processing = true
      this.$http.put(`/api/production/clients/${this.client.id}`, {
          client: this.paymentTermsForm
        })
        .then(response => {
          // console.log(response)
          this.client.payment_terms = response.data.payment_terms
          this.client.sap_payment_terms_code = response.data.sap_payment_terms_code
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios se guardaron'
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(() => {
          this.processing = false
          this.paymentTermsModalShow = false
          this.alertShow = true
        })
    },
    openNewFormModal () {
      this.formMode = 'new'
      this.form.name = null
      this.form.address = null
      this.form.project_type = null
      this.form.manager = null
      this.form.work_order_no = null
      this.form.user_id = this.$store.state.currentUser.id
      this.formModalShow = true
    },
    openEditFormModal (projectId) {
      this.formMode = 'edit'
      this.selectedProject = this.projectData[projectId]
      this.form.name = this.selectedProject.name
      this.form.address = this.selectedProject.address
      this.form.project_type = this.selectedProject.project_type
      this.form.manager = this.selectedProject.manager
      this.form.work_order_no = this.selectedProject.work_order_no
      this.form.user_id = this.selectedProject.user_id
      this.formModalShow = true
    },
    createProject () {
      if (this.processing) { return }
      this.processing = true
      let data = this.form
      data['client_id'] = this.id
      this.$http.post('/api/production/projects', { project: data })
        .then(response => {
          // console.log(response)
          let newProject = response.data
          newProject['detailShow'] = false
          newProject['loading'] = false
          this.$set(this.projectData, newProject.id, newProject)
          this.projectList.unshift(newProject.id)
          this.alertVariant = 'success'
          this.alertMessage = 'Proyecto se ha creado con éxito'
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
          this.formModalShow = false
        })
    },
    updateProject () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/production/projects/${this.selectedProject.id}`, {
          project: this.form
        })
        .then(response => {
          // console.log(response)
          this.projectData[response.data.id].name = response.data.name
          this.projectData[response.data.id].address = response.data.address
          this.projectData[response.data.id].project_type = response.data.project_type
          this.projectData[response.data.id].work_order_no = response.data.work_order_no
          this.projectData[response.data.id].manager = response.data.manager
          // TODO: update User
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios se guardaron'
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'No se pudo guardar los cambios'
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
          this.formModalShow = false
        })
    },
    createAcceso (type) {
      if (this.processing) return
      this.processing = true
      this.$refs[type].textContent = 'Procesando...'
      let data = {}
      if (type === 'corredizos') {
        data = {
          corredizo: {
            client_id: this.id,
            project_id: this.selectedProject.id,
            user_id: this.selectedProject.user_id
          }
        }
      } else if (type === 'abatibles') {
        data = {
          abatible: {
            client_id: this.id,
            project_id: this.selectedProject.id,
            user_id: this.selectedProject.user_id
          }
        }
      } else if (type === 'seccionales') {
        data = {
          seccional: {
            client_id: this.id,
            project_id: this.selectedProject.id,
            user_id: this.selectedProject.user_id
          }
        }
      } else if (type === 'cortinas') {
        data = {
          cortina: {
            client_id: this.id,
            project_id: this.selectedProject.id,
            user_id: this.selectedProject.user_id
          }
        }
      }
      this.$http.post(`/api/production/${type}`, data)
        .then(response => {
          // console.log(response)
          window.location.href = response.data.url
        })
        .catch(error => {
          console.log(error)
        })
        .finally(this.processing = false)
    },
    copyAcceso(acceso) {
      if (this.processing) return
      this.processing = true
      const str = acceso.acceso_id.substring(0, 2)
      let url
      if (str === 'CZ') {
        url =  `/api/production/corredizos/${acceso.id}/copy`
      } else if (str === 'AB') {
        url =  `/api/production/abatibles/${acceso.id}/copy`
      } else if (str === 'SE') {
        url =  `/api/production/seccionales/${acceso.id}/copy`
      } else if (str === 'CT') {
        url =  `/api/production/cortinas/${acceso.id}/copy`
      }
      this.$http.get(url)
        .then(response => {
          // console.log(response)
          const copiedAcceso = response.data
          this.projectData[copiedAcceso.project_id]['accesos'].unshift(copiedAcceso)
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
        })
        .finally(this.processing = false)
    },
    accesoImgSrc (accesoId) {
      const str = accesoId.substring(0, 2)
      if (str === 'CZ') {
        return CorredizoImage
      } else if (str === 'AB') {
        return AbatibleImage
      } else if (str === 'SE') {
        return SeccionalImage
      } else if (str === 'CT') {
        return CortinaImage
      }
    },
    barVariant (value) {
      if (value === 100 ) {
        return 'success'
      } else if (value > 50) {
        return 'warning'
      } else {
        return 'danger'
      }
    },
    bTabClass(ind) {
      if (this.bTabIndex === ind) {
        return ['font-weight-bold ','text-primary']
      } else {
        return ['text-muted']
      }
    },
    bgContactVariant (value) {
      if (this.contacts && value == this.client.contact_person) {
        return 'info'
      }
    },
    bgAddressVariant (value) {
      if (this.addresses && value == this.client.default_address) {
        return 'info'
      }
    },
    inactivate () {
      if (this.processing) return
      this.confirmModal = false
      this.isInactivatingClient = true
      this.$http.patch(`/api/production/clients/${this.id}/inactivate`)
        .then((resp) => {
          // console.log('resp', resp)
          this.client.sap_valid = resp.data.sap_valid
          this.alertVariant = 'success'
          this.alertMessage = 'Cliente inactivado correctamente'
          this.alertShow = true
        })
        .catch((err) => {
          console.error(err)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó, por favor intente de nuevo'
          this.alertShow = true
        })
        .finally(() => this.isInactivatingClient = false)
    },
    closeConfirmModal() {
      this.confirmModal = false
    }
  }
}
</script>

<style scoped>
.lower-row {
  padding: 0 3em 0 3em;
  font-size: 12px;
  color: #aaa;
}
.project-card {
  font-size: 15px;
  line-height: 2;
  margin-bottom: 1.5em;
}
.project-card-title {
  font-size: 16px;
  color: #163D7D;
}
.card-header {
  padding-top: 2px !important;
  background-color: #F5F6FC;
}
.card-body {
  padding: 0 !important;
}
.acceso-link {
  font-size: 14px;
  text-decoration: none;
  height: 20px !important;
  color: #596981;
}
.acceso-row {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.acceso-row:hover {
  background-color: #f2f2f2;
}
.progress-label {
  font-size: 12px;
}
@media (max-width: 575.98px) {
  .acceso-row-label  { font-size: 12px; }
  .progress-label { font-size: 10px; }
}
.acceso-row-dropdown {
  padding: 10px 0;
}
.acceso-row-dropdown span {
  font-size: 13px;
}
.project-subheader {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 0;
}
.table-contacts td {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
