<template>
  <div>
    <!-- Transaction captions -->
    <div class="pt-3 px-4">
      <b-row class="pl-2">
        <b-col lg="4" class="mb-2 mb-lg-0">
          <b-row style="margin-bottom: 2.5em;">
            <span v-if="isManager" class="font-lato fs-20">Historial de transacciones</span>
            <span v-else class="font-lato fs-20">Mis transacciones</span>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="pl-2">
        <!-- Search Bar -->
        <b-col lg="5" md="12" sm="3" class="pt-1">
          <b-form-group style="max-width: 350px">
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                autofocus
                :disabled="searching"
                placeholder="Empleado, UN, Comercio, transacción"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" :disabled="searching" size="sm" @click="search">
                  <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
              <b-input-group-append class="ml-3">
                <b-button variant="outline-secondary" size="sm" @click="resetSearch">
                  <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- view filter -->
        <b-col lg="7" md="12" sm="9">
          <b-row class="px-2" align-h="center">
            <b-col lg="6" cols="4">
              <b-container class="p-0 m-0">
                <b-row v-if="isManager || isTransactionAssetAdmin || isDev" align-h="between" align-v="center" class="pl-3">
                  <span class="fs-12" style="font-weight: 400">
                    <div>
                      Otras opciones:
                    </div>
                  </span>
                </b-row>
                <hr v-if="isManager || isTransactionAssetAdmin || isDev" class="mt-1 mb-2">
                <b-row v-if="isManager" class="pl-3">
                  <b-checkbox v-model="viewFilter" size="sm" switch v-b-tooltip.hover.v-dark.topright='"Nota: Habilita transacciones de otras unidades donde la unidad que asume es la nuestra"'>
                    <small class="fs-12">
                      <span v-if="viewFilter">Ocultar</span>
                      <span v-else>Mostrar</span>
                      otras unidades
                    </small>
                  </b-checkbox>
                </b-row>
                <b-row v-if="isTransactionAssetAdmin || isDev" class="pl-3">
                  <b-checkbox v-model="assetsModalShow" @change="showAssetModal" size="sm" switch v-b-tooltip.hover.v-dark.topright='"Nota: Habilita la vista de activos fijos creados"'>
                    <small class="fs-12">
                      Abrir activos fijos</small>
                  </b-checkbox>
                </b-row>
              </b-container>
            </b-col>
            <!-- Status Filter -->
            <b-col lg="6" cols="8">
              <b-container id="status-filter-group" class="p-0 m-0">
                <b-row align-h="between" align-v="center" class="clickable px-4">
                  <span class="fs-12" style="font-weight: 400">Status:</span>
                </b-row>
                <hr class="mt-1 mb-2">
                <b-row class="px-4">
                  <b-form-group class="m-0 px-1">
                    <b-checkbox v-model="statusFilter" size="sm" value="aprobada" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"Aprobadas: Listas"'>Aprobadas</b-checkbox>
                    <b-checkbox v-model="statusFilter" size="sm" value="en_proceso" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"En proceso: Pendiente el submission de device magic"'>En proceso</b-checkbox>
                    <b-checkbox v-model="statusFilter" size="sm" value="por_aprobar" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"Por aprobar: Pendiente de aprobación"'>Por aprobar</b-checkbox>
                  </b-form-group>
                  <b-form-group class="m-0 px-1">
                    <b-checkbox v-model="statusFilter" size="sm" value="pendiente_af" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"Pendiente AF: Pendiente registrar los AF en las líneas"'>Pendiente AF</b-checkbox>
                    <b-checkbox v-model="statusFilter" size="sm" value="pendiente" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"Pendiente: Pendiente realizar el formulario de registro"'>Pendiente</b-checkbox>
                    <b-checkbox v-model="statusFilter" size="sm" value="rechazada" :disabled="searching" switch v-b-tooltip.hover.v-dark.topright='"Rechazada: Transacción no cumple la validación"'>Rechazada</b-checkbox>
                  </b-form-group>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- Transaction table (desktop) -->
    <b-row align-v="center" class="mb-1 mx-0 d-none d-xl-block">
      <b-col class="py-1 px-0">
        <b-table
          :fields="tableFields"
          :items="transactions"
          head-variant="dark"
          fixed
          hover
          show-empty
          :tbody-tr-class="rowClass"
          :thead-class="['fs-23']"
          class="fs-12"
        >
          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No hay resultados</span>
            </div>
          </template>
          <!-- Table data -->
          <template v-slot:cell(user)="data">
            <span :class="{'text-danger': data.value.status == 'inactivo'}">
              {{ data.value | concatName2 | titleize }}
            </span>
          </template>
          <template v-slot:cell(created_at)="data">
            <span>{{ data.value | formatDate }}</span>
          </template>
          <!-- CARD UN -->
          <template v-slot:cell(user_business_unit)="data">
            <span v-if="data.value">{{ data.value}}</span>
          </template>
          <!-- TRANSACTION UN -->
          <template v-slot:cell(business_unit)="data">
            <span v-if="data.value">{{ data.value}}</span>
          </template>
          <template v-slot:cell(transaction_date)="data">
            <span>{{ data.value | formatDate }}</span>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge v-if="data.value == 'pendiente'" pill variant="danger" style="font-size: 90%; width: 80px">Pendiente</b-badge>
            <b-badge v-else-if="data.value == 'en_proceso'" pill variant="warning" style="font-size: 90%; width: 80px">En proceso</b-badge>
            <b-badge
              v-else-if="data.value == 'por_aprobar' && isManager && currentUser.id != data.item.user.id"
              @click="statusModalShow = true; onModalShow(data)"
              pill
              variant="info"
              class="clickable"
              style="font-size: 90%; width: 3.7rem"
            >
              <span>Aprobar</span>
              <font-awesome-icon :icon="['fas', 'caret-down']" class="text-dark ml-2" size="lg" fixed-width/>
            </b-badge>
            <b-badge v-else-if="data.value == 'por_aprobar'" pill variant="info" style="font-size: 90%; width: 80px">Por aprobar</b-badge>
            <b-badge v-else-if="data.value == 'aprobada'" pill variant="success" style="font-size: 90%; width: 80px">Aprobada</b-badge>
            <b-badge v-else-if="data.value == 'pendiente_af'" pill class="badge-orange" style="font-size: 90%; width: 80px">Asignar AF</b-badge>
            <b-badge v-else-if="data.value == 'rechazada'" pill class="badge-dark" style="font-size: 90%; width: 80px">Rechazada</b-badge>
            <b-badge v-else pill variant="secondary" class="clickable" style="font-size: 90%; width: 80px">{{ data.value }}</b-badge>
          </template>
          <template #cell(total)="data">
            <span v-if="data.item.currency == 'USD'">{{data.item.total_usd | dollarize}}</span>
            <span v-else> {{ data.value | colones2 }}</span>
          </template>
          <template #cell(sap_is_synced)="data">
            <span v-if="data.value">
              <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
            </span>
          </template>
          <template v-slot:cell(show_details)="row">
            <Button size="sm" style="width: 25px" @click="showRowDetails(row.item, row.index)">
              <font-awesome-icon v-if="row.detailsShowing" :icon="['fas', 'caret-down']" size="lg" fixed-width />
              <font-awesome-icon v-else :icon="['fas', 'caret-right']" size="lg" fixed-width />
            </Button>
          </template>
          <template v-slot:row-details="row">
            <!-- header fields -->
            <b-card no-body>
              <b-tabs card active-nav-item-class="font-weight-bold text-primary" v-model="bTabIndex">
                <b-tab :title-link-class="bTabClass(0)">
                  <template #title>
                    <strong class="fs-16">General</strong>
                  </template>
                  <b-row class="px-2">
                    <b-col cols="3" class="p-0">
                      <table class="table table-sm table-borderless mb-0">
                        <tbody>
                          <tr class="p-0">
                            <td style="width:7rem" class="p-0 font-weight-bold">Tarjeta:</td>
                            <td v-if="row.item.user_credit_card" class="p-0 pl-1">{{ row.item.user_credit_card }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td style="width:7rem" class="p-0 font-weight-bold">Placa:</td>
                            <td v-if="row.item.car_id" class="p-0 pl-1">{{ row.item.car_id }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">Cédula proveedor:</td>
                            <td v-if="row.item.business_id" class="p-0 pl-1">{{ row.item.business_id }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">UN asume:</td>
                            <td v-if="row.item.business_unit" class="p-0 pl-1">{{ row.item.business_unit }}</td>
                            <td v-else class="p-0 pl-1">{{ row.item.user_business_unit }}</td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">Orden:</td>
                            <td v-if="row.item.order_doc_no" class="p-0 pl-1">{{ row.item.order_doc_no }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col cols="9" class="p-0">
                      <table class="table table-sm table-borderless mb-0">
                        <tbody>
                          <tr class="p-0">
                            <td style="width:9rem" class="p-0 font-weight-bold">Orden de Compra:</td>
                            <td v-if="row.item.purchase_order" class="p-0 pl-1">{{ row.item.purchase_order }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">Entrada de mercadería:</td>
                            <td v-if="row.item.product_receipt" class="p-0 pl-1">{{ row.item.product_receipt }}</td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">Foto link:</td>
                            <td v-if="row.item.photos.length > 0" class="p-0 pl-1 text-secondary">
                              <b-link :href="`${row.item.photos[0].img_url_lg}`" target="_blank">
                                <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-primary"/>
                                <!-- <small class="text-primary">Abrir</small> -->
                              </b-link>
                            </td>
                            <td v-else-if="row.item.photo_link" class="p-0 pl-1">
                              <b-link :href="`${row.item.photo_link}`" target="_blank">
                                <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-primary"/>
                                <!-- <small class="text-primary">Abrir</small> -->
                              </b-link>
                            </td>
                            <td v-else class="p-0 pl-1 text-secondary"> - </td>
                          </tr>
                          <tr class="p-0">
                            <td class="p-0 font-weight-bold">Comentarios:</td>
                            <td v-if="row.item.notes" class="p-0 pl-1">{{ row.item.notes }}</td>
                            <td v-else> - </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                  <!-- rejection_notes -->
                  <b-row v-if="row.item.rejection_notes" class="px-2">
                    <b-col class="p-0">
                      <b v-if="row.item.status == 'aprobada'" class="text-info">Comentario de aprobación:</b>
                      <b v-else class="text-danger">Comentario de rechazo:</b>
                      <pre :class="row.item.status == 'aprobada' ? 'text-info' : 'text-danger'">{{ row.item.rejection_notes }}</pre>
                    </b-col>
                  </b-row>
                  <!-- items subfields table -->
                  <b-row class="px-2">
                    <b-col class="p-0">
                      <p class="font-weight-bold fs-12 m-0"><u>Detalle factura:</u></p>
                      <b-table
                        :items="row.item.card_transaction_items"
                        :fields="ItemSubfields"
                        primary-key="id"
                        head-variant="light"
                        bordered
                        show-empty
                        small
                        class="fs-12 px-5 my-1"
                        >
                        <template #empty>
                          <div class="text-center text-danger">
                            <span>Sin registros</span>
                          </div>
                        </template>
                        <template #cell(total)="data">
                          <span v-if="row.item.currency == 'USD'">{{data.value | dollarize}}</span>
                          <span v-else> {{ data.value | colones2 }}</span>
                        </template>
                        <template v-slot:cell(purchase_type)="data">
                          <span>{{ data.item.purchase_account_code.purchase_type }}</span>
                        </template>
                        <template v-slot:cell(purchase_subtype)="data">
                          <span>{{ data.item.purchase_account_code.purchase_subtype }}</span>
                        </template>
                        <template v-slot:cell(created_at)="data">
                          <span>{{ data.value | formatDate }}</span>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <!-- Assets -->
                  <b-row v-if="selectedTransactionAssets.length > 0" class="px-2">
                    <b-col class="p-0">
                      <p class="font-weight-bold fs-12 m-0"><u>Activos asignados:</u></p>
                      <b-table
                        :items="selectedTransactionAssets"
                        :fields="AssetSubfields"
                        primary-key="id"
                        head-variant="light"
                        bordered
                        small
                        class="fs-12 px-5 my-2"
                        >
                        <template #cell(total)="data">
                          <span v-if="selectedTransaction.currency == 'USD'">{{data.value | dollarize}}</span>
                          <span v-else> {{ data.value | colones2 }}</span>
                        </template>
                        <template v-slot:cell(created_at)="data">
                          <span>{{ data.value | formatDate }}</span>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <!-- actions -->
                  <b-row align-h="end" class="px-2">
                    <b-button
                      v-if="['pendiente', 'en_proceso'].includes(selectedTransaction.status) && selectedTransaction.card_transaction_items.length == 0"
                      size="sm"
                      pill
                      variant="outline-info"
                      @click="showRegisterModal()"
                    >
                      <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                      <span>Registrar</span>
                    </b-button>
                    <b-button
                      v-if="selectedTransaction.status == 'pendiente' && selectedTransaction.card_transaction_items.length > 0"
                      size="sm"
                      pill
                      variant="warning"
                      @click="showRegisterModal()"
                    >
                      <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
                      <span>Editar</span>
                    </b-button>
                    <b-button
                      v-if="selectedTransaction.status == 'pendiente_af' && (isTransactionAssetAdmin || isDev)"
                      size="sm"
                      pill
                      variant="danger"
                      class="mr-1"
                      @click="openStatusConfirmModal()"
                    >
                      <span>Rechazar</span>
                    </b-button>
                    <b-button
                      v-if="selectedTransaction.status == 'pendiente_af' && (isTransactionAssetAdmin || isDev)"
                      size="sm"
                      pill
                      variant="warning"
                      @click="onAssetModalShow()"
                    >
                      <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                      <span>Agregar</span>
                    </b-button>
                  </b-row>
                </b-tab>
                <!-- Add v-if with only administrators -->
                <b-tab v-if="isTransactionAdmin || isDev" :title-link-class="bTabClass(1)">
                  <template #title>
                    <strong class="fs-16">Admin</strong>
                  </template>
                  <b-row>
                    <b-col cols="2">
                      <h5>¿Qué acción desea ejecutar?</h5>
                      <p class="text-muted" style="position: absolute; bottom: 0; right: 40px">
                        Módulo admin: {{ currentUser | concatName2 }}
                      </p>
                    </b-col>
                    <b-col>
                      <table class="table table-borderless fs-18 table-sm mt-4">
                        <tr>
                          <td>Marcar como pendiente:</td>
                          <td>
                            <Button
                              id="set-pending-btn"
                              size="md"
                              variant="blue"
                              style="width: 100px;"
                              :disabled="row.item.status != 'rechazada' || processing"
                              @click="statusForm.status = 'pendiente'; updateStatus('marcar como pendiente')"
                            >
                              <span>Cambiar</span>
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 220px;">Reenviar dispatch:</td>
                          <td>
                            <Button
                              id="resend-dispatch-btn"
                              size="md"
                              variant="yellow"
                              style="width: 100px;"
                              :disabled="(row.item.status != 'en_proceso' && row.item.status != 'pendiente') || !row.item.dispatch_id || sendingDispatch"
                              @click="retryDispatch(row.item.dispatch_id)"
                            >
                              <span v-if="!sendingDispatch">Reenviar</span>
                              <span v-else class="pl-1">
                                <b-spinner small type="grow"></b-spinner>
                                <span class="pl-1">Enviando</span>
                              </span>
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>Rechazar:</td>
                          <td>
                            <Button
                              id="reject-btn"
                              size="md"
                              style="width: 100px;"
                              @click="statusForm.status = 'rechazada'; statusConfirmModalShow = true"
                              :disabled="row.item.status != 'pendiente' && row.item.status != 'en_proceso'"
                            >
                              Rechazar
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>Eliminar:</td>
                          <td>
                            <Button
                              id="delete-btn"
                              size="md"
                              variant="red"
                              style="width: 100px;"
                              @click="inactivateTransaction"
                              :disabled="row.item.status == 'aprobada'"
                            >
                              Eliminar
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- Transaction table (mobile) -->
    <b-row align-v="center" class="mb-1 mx-0 d-xl-none">
      <b-col>
        <hr>
        <b-row v-for="(transaction, index) in transactions" :key="transaction.id" align-h="center">
          <b-col>
            <b-card class="mb-3">
              <b-container fluid class="p-0 m-0 fs-12">
                <!-- Header -->
                <b-row align-v="end" class="pb-3">
                  <b-col cols="6" class="text-left">
                    <b class="text-primary">{{ transaction.transaction_date | formatDate }}</b>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-badge v-if="transaction.status == 'pendiente'" pill variant="danger" class="clickable" style="font-size: 90%; width: 80px">Pendiente</b-badge>
                    <b-badge v-else-if="transaction.status == 'en_proceso'" pill variant="warning" class="clickable" style="font-size: 90%; width: 80px">En proceso</b-badge>
                    <b-badge v-else-if="transaction.status == 'por_aprobar'" pill variant="info" class="clickable" style="font-size: 90%; width: 80px">Por aprobar</b-badge>
                    <b-badge v-else-if="transaction.status == 'aprobada'" pill variant="success" class="clickable" style="font-size: 90%; width: 80px">Aprobada</b-badge>
                    <b-badge v-else-if="transaction.status == 'pendiente_af'" pill class="badge-orange" style="font-size: 90%; width: 80px">Asignar AF</b-badge>
                    <b-badge v-else-if="transaction.status == 'rechazada'" pill class="badge-dark" style="font-size: 90%; width: 80px">Rechazada</b-badge>
                    <b-badge v-else pill variant="secondary" class="clickable" style="font-size: 90%; width: 80px">{{ transaction.status}}</b-badge>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col>
                    <b>{{ transaction.user_credit_card  }}</b>
                  </b-col>
                  <b-col class="text-right">
                    <b># {{ transaction.internal_code_1 }}</b>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col>
                    <b class="text-primary">{{ transaction.business_name | titleize  }}</b>
                  </b-col>
                </b-row>
                <b-row v-if="isManager">
                  <b-col class="text-left">
                    Usuario: {{ transaction.user | concatName2 | titleize }}
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2">
                  <b-col cols="6">
                    <span v-if="transaction.business_unit">Unidad asume: {{ transaction.business_unit}}</span>
                    <span v-else>Unidad: {{ transaction.user_business_unit}}</span>
                  </b-col>
                  <b-col cols="6">
                    <span v-if="transaction.currency == 'USD'">Total: {{ transaction.total_usd | dollarize  }}</span>
                    <span v-else>Total: {{ transaction.total | colones2  }}</span>
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2">
                  <b-col cols="6">
                    Placa:
                    <span v-if="transaction.car_id"> {{ transaction.car_id }}</span>
                    <span v-else> - </span>
                  </b-col>
                  <b-col cols="6">
                    Cédula:
                    <span v-if="transaction.business_id"> {{ transaction.business_id }}</span>
                    <span v-else> - </span>
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2">
                  <b-col cols="6">
                    Orden:
                    <span v-if="transaction.order_doc_no"> {{ transaction.order_doc_no }}</span>
                    <span v-else> - </span>
                  </b-col>
                  <b-col cols="6">
                    Orden de Compra:
                    <span v-if="transaction.purchase_order"> {{ transaction.purchase_order }}</span>
                    <span v-else> - </span>
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2">
                  <b-col cols="12">
                    Entrada de mercaderia:
                    <span v-if="transaction.product_receipt"> {{ transaction.product_receipt }}</span>
                    <span v-else> - </span>
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2">
                  <b-col cols="12">
                    Foto link:
                    <b-link v-if="transaction.photos.length > 0" :href="`${transaction.photos[0].img_url_lg}`" target="_blank">
                      <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-primary"/>
                      <!-- <small class="text-primary">Abrir</small> -->
                    </b-link>
                    <b-link v-else-if="transaction.photo_link" :href="`${transaction.photo_link}`" target="_blank">
                      <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-primary"/>
                      <!-- <small class="text-primary">Abrir</small> -->
                    </b-link>
                    <span v-else> - </span>
                  </b-col>
                </b-row>
                <b-row class="text-secondary pt-2 pb-2">
                  <b-col cols="12">
                    Comentarios:
                    <span v-if="transaction.notes"> {{ transaction.notes }}</span>
                    <span v-else> - </span>
                  </b-col>
                </b-row>
                <b-row v-if="transaction.rejection_notes" :class="transaction.status == 'aprobada' ? 'text-info pt-2 pb-2' : 'text-danger pt-2 pb-2'">
                  <b-col cols="12">
                    <b v-if="transaction.status == 'aprobada'">Comentario de aprobación:</b>
                    <b v-else>Comentario de rechazo:</b>
                    <div class="mb-2">
                      <pre :class="transaction.status == 'aprobada' ? 'text-info' : 'text-danger'">{{ transaction.rejection_notes }}</pre>
                    </div>
                  </b-col>
                </b-row>
                <b-button
                  :class="transaction._showDetails ? null : 'collapsed'"
                  :aria-expanded="transaction._showDetails ? 'true' : 'false'"
                  aria-controls="show-lines"
                  size="sm"
                  variant="info"
                  block
                  @click="showRowDetails(transaction, index)"
                >
                  <span v-if="transaction._showDetails">Ocultar líneas</span>
                  <span v-else>Mostrar detalle</span>
                </b-button>
                <b-collapse id="show-lines" v-model="transaction._showDetails" class="mt-2">
                  <b-card>
                    <b-container v-if="transaction.card_transaction_items.length == 0" fluid class="fs-13 mb-2">
                      <b-card>
                        <b-row align-h="center" class="m-2">
                          <span class="text-danger">Sin registros</span>
                        </b-row>
                      </b-card>
                    </b-container>
                    <b-table
                      v-else
                      responsive
                      :items="transaction.card_transaction_items"
                      :fields="ItemSubfields"
                      class="fs-12"
                    >
                      <template #cell(total)="data">
                        <span v-if="data.item.currency == 'USD'">{{data.value | dollarize}}</span>
                        <span v-else> {{ data.value | colones2 }}</span>
                      </template>
                      <template v-slot:cell(purchase_type)="data">
                        <span>{{ data.item.purchase_account_code.purchase_type }}</span>
                      </template>
                      <template v-slot:cell(purchase_subtype)="data">
                        <span>{{ data.item.purchase_account_code.purchase_subtype }}</span>
                      </template>
                      <template v-slot:cell(created_at)="data">
                        <span>{{ data.value | formatDate }}</span>
                      </template>
                    </b-table>
                    <hr>
                    <b-table
                      v-if="transaction.card_transaction_assets.length > 0"
                      responsive
                      :items="transaction.card_transaction_assets"
                      :fields="AssetSubfields"
                      class="fs-12"
                    >
                      <template #cell(total)="data">
                        <span v-if="data.item.currency == 'USD'">{{data.value | dollarize}}</span>
                        <span v-else> {{ data.value | colones2 }}</span>
                      </template>
                      <template v-slot:cell(created_at)="data">
                        <span>{{ data.value | formatDate }}</span>
                      </template>
                    </b-table>
                    <b-row align-h="around">
                      <b-button
                        v-if="transaction.status == 'por_aprobar' && isManager && currentUser.id != transaction.user.id"
                        size="sm"
                        variant="primary"
                        @click="statusModalShow = true"
                      >
                        Aprobar
                      </b-button>
                      <b-button
                        v-if="['pendiente', 'en_proceso'].includes(transaction.status) && transaction.card_transaction_items.length == 0"
                        size="sm"
                        variant="primary"
                        @click="showRegisterModal()"
                      >
                        Registrar
                      </b-button>
                      <b-button
                        v-if="transaction.status == 'pendiente' && transaction.card_transaction_items.length > 0"
                        size="sm"
                        variant="warning"
                        @click="showRegisterModal()"
                      >
                        Editar
                      </b-button>
                      <b-button
                        v-if="transaction.status == 'pendiente_af' && (isTransactionAssetAdmin || isDev)"
                        size="sm"
                        variant="danger"
                        @click="openStatusConfirmModal()"
                      >
                        Rechazar
                      </b-button>
                      <b-button
                        v-if="transaction.status == 'pendiente_af' && (isTransactionAssetAdmin || isDev)"
                        size="sm"
                        variant="warning"
                        @click="onAssetModalShow()"
                      >
                        Agregar
                      </b-button>
                    </b-row>
                  </b-card>
                </b-collapse>
              </b-container>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Page tab-->
    <div class="mt-y text-center">
      <PaginationCountless :page="page" :next="next" :prev="prev">
        <template #prev-label>
          <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
        </template>
        <template #next-label>
          <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
        </template>
      </PaginationCountless>
    </div>

    <!-- Card transaction Asset modal -->
    <AssetsModal
      @modal-hidden="hideAssetModal"
      @find-transaction="findTransaction"
    />

    <!-- Card transaction register modal -->
    <RegisterModal
      v-if="selectedTransaction"
      :transaction="selectedTransaction"
      :purchase-type-options="purchaseTypeOptions"
      @modal-hidden="hideRegisterModal"
      @update-transaction="updateTransactionObject"
    />

    <!-- Status Modal -->
    <b-modal
      v-model="statusModalShow"
      size="sm"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @hide="onHideStatusModal()"
    >
      <template #modal-header="{ close }">
        <span class="fs-18 mt-2 text-secondary">Modelo de aprobación</span>
        <b-button size="sm" class="p-0 mt-2 m-0" variant="outline-danger" @click="close()">
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
        </b-button>
      </template>
      <b-row align-h="center" class="text-center" v-if="selectedTransaction">
        <b class="fs-18 m-1">¿Aprobar transacción {{selectedTransaction.internal_code_1}}?</b>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: Tenga en cuenta que si aprueba la transacción será enviada a SAP</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" size="sm" class="pl-1 pr-0" @click="statusForm.status = 'rechazada'; statusConfirmModalShow = true">
          Rechazar
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-light pl-1"/>
        </Button>
        <Button variant="yellow" size="sm" class="pl-1 pr-0 text-light"  @click="statusForm.status = 'pendiente'; statusConfirmModalShow = true">
          Reintentar
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-light pl-1"/>
        </Button>
        <Button variant="green" size="sm" class="pl-1 pr-0" @click="statusForm.status = 'aprobada'; statusConfirmModalShow = true">
          Aprobar
          <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-light pl-1"/>
        </Button>
      </b-row>
    </b-modal>

    <b-modal
      v-model="statusConfirmModalShow"
      id="rejection-notes-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @hide="onHideStatusModal()"
    >
      <template #modal-header="{ close }">
        <div class="fs-18 mt-2 text-primary">
          <span>Comentario de </span>
          <span v-if="statusForm.status == 'aprobada'">aprobación</span>
          <span v-else>rechazo</span>
        </div>
        <b-button size="sm" class="p-0 mt-2 m-0" variant="outline-danger" @click="close()">
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
        </b-button>
      </template>
      <b-row>
        <b-col>
          <p v-if="statusForm.status != 'aprobada'" class="text-muted text-center">NOTA: Se enviará un correo al dueño de la transacción con el comentario de rechazo</p>
          <b-form-textarea
            id="rejection-notes-field"
            v-model="statusForm.rejection_notes"
            :style="{'border': (statusForm.status != 'aprobada' && !statusForm.rejection_notes) || statusForm.rejection_notes.length > 100 ? '.5px red solid' : '.5px green solid'}"
            placeholder="Ingrese el motivo, máximo 100 caracteres..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-2">
        <Button v-if="statusForm.status == 'aprobada'" variant="blue" size="sm" :disabled="statusForm.rejection_notes.length > 100" class="px-1" @click="updateStatus('aprobar')">
          Confirmar
        </Button>
        <Button v-else variant="blue" size="sm" :disabled="!statusForm.rejection_notes || statusForm.rejection_notes.length > 100" class="px-1" @click="updateStatus('rechazar')">
          Confirmar
        </Button>
      </b-row>
    </b-modal>

    <!-- asset Form Modal -->
    <b-modal
      v-model="createAssetModalShow"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      scrollable
      @hide="onAssetModalHide"
    >
      <template #modal-title>
        <div class="p-0 fs-16">
          <p class="m-0">
            Transacción: {{ selectedTransaction.internal_code_1 }} | 
            <span v-if="selectedTransaction.currency == 'USD'">{{ selectedTransaction.total_usd | dollarize }}</span>
            <span v-else> {{ selectedTransaction.total | colones2 }}</span>
          </p>
        </div>
      </template>
      <b-row align-h="center" class="p-2" v-if="selectedTransaction && assetItemsForm">
        <b-col>
          <!-- asset items -->
          <ValidationObserver ref="observer2" v-slot="{invalid}">
            <b-row>
              <table class="table border-left border-right border-bottom mb-1">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th class="p-0">Activo<span class="asterisk">*</span></th>
                    <th class="p-0">Cantidad<span class="asterisk">*</span></th>
                    <th class="p-0">Monto<span class="asterisk">*</span></th>
                    <th class="p-0"></th>
                  </tr>
                </thead>
                <tbody ref="itemContainer1">
                  <tr v-for="(item, index) in assetItems" :key="index">
                    <!-- Asset code -->
                    <td class="align-middle p-1">
                      <ValidationProvider :rules="{required: true}" name="AF" v-slot="{ valid, errors }">
                        <b-form class="p-1">
                          <b-input
                            type="text"
                            v-model="item.asset_code"
                            size="sm"
                            style="width: 84%;"
                            :state="errors[0] ? false : (valid ? true : null)"
                          />
                        </b-form>
                      </ValidationProvider>
                    </td>
                    <!-- Quantity -->
                    <td class="align-middle p-1 pl-2 fs-13">
                      <ValidationProvider :rules="{required: true, numeric: true, min_value: 1 }" name="Cantidad AF" v-slot="{ valid, errors }">
                        <b-form class="p-1">
                          <b-input
                            type="number"
                            v-model="item.quantity"
                            size="sm"
                            style="width: 84%;"
                            :state="errors[0] ? false : (valid ? true : null)"
                          />
                        </b-form>
                      </ValidationProvider>
                    </td>
                    <!-- Total -->
                    <td class="align-middle p-1">
                      <ValidationProvider :rules="{required: true, double: true}" name="monto af" v-slot="{ valid, errors }">
                        <b-form class="p-1">
                          <b-input
                            type="number"
                            v-model="item.total"
                            size="sm"
                            style="width: 84%;"
                            :state="errors[0] ? false : (valid ? true : null)"
                          />
                        </b-form>
                      </ValidationProvider>
                    </td>
                    <!-- delete row -->
                    <td class="p-1" v-if="index > 0">
                      <Button variant="red" size="sm" @click="deleteAssetItem(index)">
                        <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                      </Button>
                    </td>
                  </tr>
                  <tr class="p-0">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="p-0">
                      <Button variant="green" size="sm" class="m-1" @click="addAssetItem()">
                        <span class="mr-1">Nueva línea</span>
                        <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row align-h="around" class="mx-4 pt-2">
              <Button variant="red" @click="createAssetModalShow = false">
                Cancelar
              </Button>
              <Button
                variant="green"
                :disabled="invalid || !validAssetItemsForm"
                @click="createAssetItems()"
              >
                Actualizar
              </Button>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from "vee-validate";

import Button from '../../shared/Button.vue'
import PaginationCountless from '../../shared/PaginationCountless';

import AssetsModal from './AssetsModal';
import RegisterModal from './RegisterModal';
import ToastAlert from '../../shared/ToastAlert'

export default {
  name: 'CardTransactionIndex',
  components: {
    ValidationObserver,
    ValidationProvider,
    PaginationCountless,
    Button,
    AssetsModal,
    RegisterModal,
    ToastAlert
  },
  data() {
    return {
      cardTransactions: [],
      cardTransactionsItems: [],
      selectedTransaction: null,
      selectedTransactionAssets: [],
      selectedTransactionIndex: null,
      fields: [
        { key: 'show_details', label: '', tdClass: 'text-center', thStyle: { width: '10px' } },
        { key: 'user', label: 'Usuario', tdClass: 'clickable', thStyle: { width: '70px' } },
        { key: 'user_business_unit', label: 'Unidad tarjeta', tdClass: 'clickable', thStyle: { width: '30px' } },
        { key: 'business_unit', label: 'Unidad asume', tdClass: 'clickable',thClass: 'text-center', thStyle: { width: '30px' } },
        { key: 'internal_code_1', label: 'transacción', tdClass: 'clickable text-center', thStyle: { width: '55px' } },
        { key: 'business_name', label: 'Comercio', tdClass: 'clickable', thStyle: { width: '210px' } },
        { key: 'total', label: 'Monto total', tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '55px' } },
        { key: 'status', label: 'status', tdClass: 'clickable text-center', thClass: 'text-center', thStyle: { width: '60px' } },
        { key: 'transaction_date', label: 'Fecha', tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '40px' } },
      ],
      managerFields: [
        { key: 'show_details', label: '', tdClass: 'text-center', thStyle: { width: '10px' } },
        { key: 'user', label: 'Usuario', tdClass: 'clickable', thStyle: { width: '70px', margin: '30px'} },
        { key: 'user_business_unit', label: 'Unidad tarjeta', tdClass: 'clickable text-center', thStyle: { width: '30px' } },
        { key: 'business_unit', label: 'Unidad asume', tdClass: 'clickable text-center',thClass: 'text-center', thStyle: { width: '30px' } },
        { key: 'internal_code_1', label: 'Transacción', tdClass: 'clickable', thStyle: { width: '55px' } },
        { key: 'business_name', label: 'Comercio', tdClass: 'clickable', thStyle: { width: '210px' } },
        { key: 'total', label: 'Monto total', tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '55px' } },
        { key: 'status', label: 'Status', tdClass: 'clickable text-center', thClass: 'text-center', thStyle: { width: '30px' } },
        { key: 'transaction_date', label: 'Fecha', tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '40px' } },
        { key: 'sap_is_synced', label: 'SAP', tdClass: 'clickable text-center', thClass: 'text-center', thStyle: { width: '10px' } }
      ],
      ItemSubfields: [
        { key: 'purchase_type', label: 'Tipo', tdClass: 'align-middle  text-center font-weight-bold', thClass: 'text-center', thStyle: { width: '150px' } },
        { key: 'purchase_subtype', label: 'Subtipo', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '150px' }},
        { key: 'total', label: 'Total', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '50px' }},
        { key: 'created_at', label: 'Fecha creación', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '100px' }}
      ],
      AssetSubfields: [
        { key: 'asset_code', label: 'Activo', tdClass: 'align-middle  text-center font-weight-bold', thClass: 'text-center', thStyle: { width: '154px' } },
        { key: 'quantity', label: 'Cantidad', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '150px' }},
        { key: 'total', label: 'Total', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '50px' }},
        { key: 'created_at', label: 'Fecha creación', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '103px' }}
      ],
      statusForm : {
        status: null,
        rejection_notes: ''
      },
      assetItemsForm: [],
      purchaseTypeOptions: [],
      page: 1,
      next: null,
      prev: null,
      searchTerm: '',
      loading: false,
      processing: false,
      sendingDispatch: false,
      searching: false,
      noScroll: false,
      statusModalShow: false,
      statusConfirmModalShow: false,
      registerModalShow: false,
      createAssetModalShow: false,
      assetsModalShow: false,
      viewFilter: false,
      storedPreview: null,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      bTabIndex: 0
    }
  },
  computed: {
    ...mapGetters(['isManager', 'isTransactionAssetAdmin', 'isDev', 'isTransactionAdmin']),
    statusFilter: {
      get() {
        return this.$store.state.transactionStatusFilter;
      },
      set(val) {
        this.$store.commit('setTransactionStatusFilter', val);
      }
    },
    currentUser () { return this.$store.state.currentUser },
    tableFields () {
      return this.isManager ? this.managerFields : this.fields;
    },
    transactions () {
      return this.cardTransactions?.map(item => {
        this.$set(item, '_showDetails', false)
        this.$set(item, 'user', item.employee_card.user)
        this.$set(item, 'user_business_unit', item.employee_card.business_unit)
        this.$set(item, 'user_credit_card', item.employee_card.credit_card_num)
        this.$set(item, 'user_card_auto_approved', item.employee_card.is_automatically_approved)
        // this.$set(item, 'dispatch_status', item.dispatch.status)
        return item
      }) || []
    },
    assetItems () {
      if (this.assetItemsForm.length > 0){
        return this.assetItemsForm
      }
      return []
    },
    validAssetItemsForm () {
      if (this.assetItemsForm.length > 0) {
        const isEveryValueNotNullable = this.assetItemsForm.every(item => {
          if (item.quantity <= 0) { return false }
          return Object.values(item).every(value => value != null && value != '' )
        })
        return isEveryValueNotNullable
      }
      return true
    }
    // grandTotalUsd () { // NOTE: NEXT VERSION!
    //   if (this.transactions.length > 0) {
    //     return this.transactions.reduce((acum, item) => {
    //       return acum + parseFloat(item.total_usd)
    //     }, 0)
    //   } else {
    //     return 0.0
    //   }
    // }
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchPaginatedTransactions();
    },
    statusFilter (newVal, oldVal) {
      this.noScroll = true;
      // this.fetchPaginatedTransactions()
      this.search()
    },
    viewFilter (newVal, oldVal) {
      this.noScroll = true;
      // this.fetchPaginatedTransactions()
      this.search()
    },
  },
  mounted () {
    this.noScroll = true;
    this.fetchPaginatedTransactions();
    this.getPurchaseTypes()
  },
  methods: {
    fetchPaginatedTransactions () {
      this.loading = true;
      this.$http.get('/api/production/card_transactions', {
          params: {
            page: this.$route.query.pagina,
            q: this.searchTerm,
            status_filter: this.statusFilter,
            view_filter: this.viewFilter
          }
        })
        .then(response => {
          console.log(response)
          this.cardTransactions = response.data.card_transactions
          this.page = response.data.pagy.page
          this.next = response.data.pagy.next
          this.prev = response.data.pagy.prev
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.noScroll = false;
        });
    },
    search () {
      if (this.searching) { return }
      this.searching = true;
      let query = Object.assign({}, this.$route.query);
      delete query.pagina;
      this.$router.replace({ query })
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.$http.get('/api/production/card_transactions', {
          params: {
            q: this.searchTerm.trim(),
            status_filter: this.statusFilter,
            view_filter: this.viewFilter
          }
        })
        .then(response => {
          this.cardTransactions = response.data.card_transactions
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setTimeout(() => { this.searching = false }, 1000);
        });
    },
    resetSearch () {
      this.searchTerm = '';
      this.$router.push('/app/prod/transacciones')
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.fetchPaginatedTransactions();
    },
    rowClass (item) {
      if (!item) return
      if (item.sap_is_synced === true) {
        return `trans_${item.id} ignored-row`
      } else {
        return `trans_${item.id}`
      }
    },
    onModalShow (data) {
      this.selectedTransaction = data.item
      this.selectedTransactionIndex = data.index
      this.transactions.forEach(transaction => { transaction._showDetails = true ? transaction._showDetails = false : ''})
    },
    showRowDetails (row, index){
      if (!row._showDetails) {
        this.transactions[index]._showDetails = true
        this.transactions.forEach(transaction => { row.id ===  transaction.id ? transaction._showDetails = true : transaction._showDetails = false})
        this.selectedTransactionIndex = index
        this.selectedTransaction = row
        this.selectedTransactionAssets = row.card_transaction_assets
      } else {
        this.transactions[index]._showDetails = false
        this.selectedTransaction = null
        this.selectedTransactionAssets = []
        this.selectedTransactionIndex = null
      }
    },
    onAssetModalShow () {
      let form = [
        {
          asset_code: null,
          total: null,
          quantity: 1
        }
      ]
      this.assetItemsForm = form
      this.createAssetModalShow = true;
    },
    onAssetModalHide () {
      this.assetItemsForm = []
    },
    addAssetItem (){
      this.assetItemsForm.push(
        { asset_code: null, total: null, quantity: 1 }
      )
    },
    deleteAssetItem (index){
      this.assetItemsForm.splice(index, 1)
    },
    createAssetItems () {
      if (this.processing) return;
      if (window.confirm(`¿Crear activos?`)) {
        const statusName = this.setStatus('por_aprobar')
        this.$http.post(`/api/production/card_transactions/${this.selectedTransaction.id}/card_transaction_assets`, {
          card_transaction: {
            status: statusName
          },
          card_transaction_assets: Object.values(this.assetItemsForm)
        })
          .then(response => {
            // console.log(response);
            this.cardTransactions[this.selectedTransactionIndex].status = statusName
            this.cardTransactions[this.selectedTransactionIndex].card_transaction_assets = response.data
            this.selectedTransactionAssets = response.data
            this.alertVariant = 'success'
            this.alertMessage = 'Activos registrados correctamente'
            this.alertShow = true
            this.createAssetModalShow = false
          })
          .catch(error => {
            console.log(error)
            this.alertVariant = 'danger'
            this.alertMessage = 'Error al realizar el registro de activos, intente nuevamente'
            this.alertShow = true
          })
          .finally(() => {
            this.processing = false
          });
      }
    },
    updateStatus (confirmMsg) {
      if (!this.statusForm.status || this.processing) { return }
      if (window.confirm(`¿Está seguro que desea ${confirmMsg} la transacción?`)) {
        this.processing = true
        let data = {
          card_transaction : {
            status:  this.statusForm.status,
            rejection_notes: this.statusForm.rejection_notes
          }
        }
        if (['pendiente', 'rechazada'].includes(this.statusForm.status) && this.statusForm.rejection_notes) {
          data.card_transaction['business_unit'] = null
          if (this.currentUser) {
            data.card_transaction['rejection_notes'] += `\n - Ejecutado por: ${this.currentUser.id}- `
            data.card_transaction['rejection_notes'] += `${this.currentUser.first_name} ${this.currentUser.last_name}, ${this.currentUser.email}`
          }
        }

        if (this.statusForm.status == 'aprobada' && this.statusForm.rejection_notes) {
          if (this.currentUser) {
            data.card_transaction['rejection_notes'] += `\n - Ejecutado por: ${this.currentUser.id}- `
            data.card_transaction['rejection_notes'] += `${this.currentUser.first_name} ${this.currentUser.last_name}, ${this.currentUser.email}`
          }
        }

        this.$http.patch(`/api/production/card_transactions/${this.selectedTransaction.id}/update_status`, data)
          .then(response => {
            console.log('response', response.data);
            this.cardTransactions[this.selectedTransactionIndex].status = response.data.card_transaction.status
            this.cardTransactions[this.selectedTransactionIndex].rejection_notes = response.data.card_transaction.rejection_notes
            this.alertVariant = 'success'
            this.alertMessage = 'Transacción actualizada correctamente'
            this.alertShow = true
          })
          .catch(error => {
            console.log(error)
            this.alertVariant = 'danger'
            this.alertMessage = 'Error al actualizar, intente nuevamente'
            this.alertShow = true
          })
          .finally(() => {
            this.statusModalShow = false
            this.statusConfirmModalShow = false
            this.processing = false
          })
      }
    },
    setStatus (status) {
      const unAssumeExpense = !this.selectedTransaction.business_unit // NOTE: selectedTransaction.business_unit == null means that same Business unit assumes the expense
      return this.selectedTransaction.user_card_auto_approved && unAssumeExpense ? 'aprobada' : status
    },
    openStatusConfirmModal () {
      this.statusForm.status = 'pendiente'
      this.statusForm.rejection_notes = ''
      this.statusConfirmModalShow = true
    },
    onHideStatusModal () {
      this.statusForm.status = null
      this.statusForm.rejection_notes = ''
    },
    getPurchaseTypes () {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/purchase_account_codes/purchase_types')
        .then(response => {
          // console.log(response);
          this.purchaseTypeOptions =  response.data.map(term => ({value: term, text: term[0].toUpperCase() + term.slice(1)}))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        })
    },
    // assets
    showAssetModal () {
      this.$bvModal.show('assets-modal')
    },
    hideAssetModal () {
      this.assetsModalShow = false
      this.$bvModal.hide('assets-modal')
    },
    findTransaction (transaction) {
      this.hideAssetModal()
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/card_transactions/${transaction.id}`)
        .then(response => {
          this.cardTransactions = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.highlightRow(transaction.id)
        });
    },
    highlightRow (transaction_id) {
      const el = document.getElementsByClassName(`trans_${transaction_id}`)[0]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center'})
        let rowClass = 'highlighted-row'
        el.classList.add(rowClass)
        setTimeout(() => el.classList.remove(rowClass), 2000)
      }
    },
    // Register modal
    updateTransactionObject (data) {
      const index = this.selectedTransactionIndex
      this.cardTransactions[index].status = data.status
      this.cardTransactions[index].business_unit = data.business_unit
      this.cardTransactions[index].business_id = data.business_id
      this.cardTransactions[index].car_id = data.car_id
      this.cardTransactions[index].order_doc_no = data.order_doc_no
      this.cardTransactions[index].photo_link = data.photo_link
      this.cardTransactions[index].product_receipt = data.product_receipt
      this.cardTransactions[index].purchase_order = data.purchase_order
      this.cardTransactions[index].notes = data.notes
      this.cardTransactions[index].photos = data.photos
      this.cardTransactions[index].card_transaction_items = data.card_transaction_items
    },
    showRegisterModal () {
      this.$bvModal.show('register-modal')
    },
    hideRegisterModal () {
      this.$bvModal.hide('register-modal')
    },
    // admin section
    bTabClass (ind) {
      if (this.bTabIndex === ind) {
        return ['font-weight-bold ','text-primary']
      } else {
        return ['text-muted']
      }
    },
    inactivateTransaction () {
      if (this.processing) { return }
      if (window.confirm(`¿Está seguro que desea eliminar la transacción?`)) {
        this.processing = true
        let data = {
          card_transaction : {
            status:  'inactiva',
            rejection_notes: `Transacción eliminada desde el módulo admin por el usuario ${this.currentUser.id} : ${this.currentUser.first_name} ${this.currentUser.last_name} ${this.currentUser.last_name2}`
          }
        }
        this.$http.patch(`/api/production/card_transactions/${this.selectedTransaction.id}/update_status`, data)
          .then(response => {
            console.log('response', response.data);
            this.cardTransactions =  this.cardTransactions.filter((item) => item.id != this.selectedTransaction.id )
            this.alertVariant = 'success'
            this.alertMessage = 'Transacción eliminada'
            this.alertShow = true
          })
          .catch(error => {
            console.log(error)
            this.alertVariant = 'danger'
            this.alertMessage = 'Error al eliminar, intente nuevamente'
            this.alertShow = true
          })
          .finally(() => this.processing = false )
      }
    },
    retryDispatch (dispatch_id) {
      if (this.sendingDispatch || !dispatch_id) { return }
      if (window.confirm(`¿Desea reenviar el dispatch de device magic al usuario?`)) {
        this.sendingDispatch = true
        this.$http.patch(`/api/production/card_transactions/${this.selectedTransaction.id}/retry_dispatch`)
          .then((res) => {
            console.log(res.data)
            this.alertVariant = 'success'
            this.alertMessage = 'Dispatch enviado correctamente'
            this.alertShow = true
          })
          .catch(e => {
            console.log(e)
            this.alertVariant = 'danger'
            this.alertMessage = 'Error al reenviar dispatch, intente nuevamente'
            this.alertShow = true
          })
          .finally( ()=> {
            setTimeout(() => {
              this.sendingDispatch = false
            }, 2000);
          })
      }
    }
  }
}
</script>

<style scoped>
.form-control-sm {
  font-size: 12px !important;
  line-height: 2 !important;
}
.badge-orange {
  background-color: #eb8e44;
}
.badge-dark {
  background-color: #575252;
}
</style>
