import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["icon", "select"];

  connect() {
    this.validate();
  }

  validate() {
    if (this.selectTarget && this.selectTarget.value) {
      this.iconTarget.classList.add('d-none');
    } else {
      this.iconTarget.classList.remove('d-none');
    }
  } 
}

